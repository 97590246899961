import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CREATIONS_LIST_TYPE,
  IReconstructJobUI,
  RECONSTRUCTION_ACTION,
  TETURE_GENERATE_TYPE,
} from '../generate';
import { GenerateService } from '../generate.service';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CreationsComponent } from '../creations/creations.component';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../environments/environment';
import { ViewerService } from '../../shared/viewer.service';
import { UtilsService } from '../../shared/utils.service';
import { ExportFileType, IExpotedModel } from 'hexa-viewer-communicator';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { ScreenNotificationType } from '../../shared/enums';
import { themeColor } from '../../shared/constants';
import { EnumsService } from '../../shared/enums.service';
import { THREE_LATEST_VERSION } from 'asset-adjustments';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-ai-texture-prompt',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CostEstComponent,
    CommonModule,
    MatButtonModule,
    CreationsComponent,
    RouterLink,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
  ],
  templateUrl: './ai-texture-prompt.component.html',
  styleUrl: './ai-texture-prompt.component.scss',
})
export class AiTexturePromptComponent implements OnInit, OnDestroy {
  private _state: TETURE_GENERATE_TYPE;
  private _examplePromptIndex: number;
  public EXISTING = TETURE_GENERATE_TYPE.EXISTING;
  public FOREIGN = TETURE_GENERATE_TYPE.FOREIGN;
  public cost: number;
  public viewerServer: string;
  public viewerService: ViewerService;
  public expordedModel: IExpotedModel;
  public hasFile: boolean;
  public loadingFiles: boolean;
  public CREATIONS_LIST_TYPE = CREATIONS_LIST_TYPE;
  public themeColor = themeColor;
  THREE_LATEST_VERSION = THREE_LATEST_VERSION;
  @ViewChild('hexaViewer') hexaViewer: ElementRef;
  constructor(
    public generateService: GenerateService,
    private utils: UtilsService,
    private resumableUploadService: ResumableUploadService,
    private enums: EnumsService
  ) {
    this.state = TETURE_GENERATE_TYPE.FOREIGN;
    this._examplePromptIndex = 0;
    this.viewerService = new ViewerService(this.utils);
    this.viewerServer = environment.viewerServer;
    this.utils.preloadImage('/assets/images/cube.svg');
  }

  ngOnInit() {
    this.init();
    const params = this.utils.getAngularUrlParams();
    if (params?.duplicate) this.duplicate(parseInt(params.duplicate));
  }

  async duplicate(id: number) {
    if (id && !isNaN(id)) {
      const job = (await this.generateService.getJobById(id)).data
        .reconstruction_jobs as IReconstructJobUI;
      switch (job.action_id) {
        case RECONSTRUCTION_ACTION.RE_TEXTURE: {
          this.state = this.EXISTING;
          this.generateService.text =
            job.reconstruction_jobs_inputs[0].text_input;
          if (job.viewer_url) this.onSelect(job);
          break;
        }
      }
    }
  }

  get state() {
    return this._state;
  }

  set state(value: TETURE_GENERATE_TYPE) {
    this._state = value;
    if (this.state === this.FOREIGN && this.hasFile) {
      this.hasFile = false;
      this.viewerService.vc.onModelLoaded().then(async () => {
        await this.utils.setTimeout();
        await this.readyForFile();
        this.hasFile = false;
        await this.viewerService.vc.onModelLoaded();
        this.hasFile = true;
        this.loadingFiles = false;
      });
    }
  }

  private async init() {
    this.cost = (
      await this.generateService.getAction(RECONSTRUCTION_ACTION.RE_TEXTURE)
    ).credits;
    this.readyForFile();
  }

  async readyForFile() {
    await this.viewerService.injectScript();
    if (!this.hexaViewer?.nativeElement)
      this.hexaViewer = new ElementRef(
        document.getElementById('ai-texture-prompt-viewer')
      );
    this.hexaViewer.nativeElement.setAttribute('server', this.viewerServer);
    this.hexaViewer.nativeElement.setAttribute('tv', THREE_LATEST_VERSION);
    this.hexaViewer.nativeElement.setAttribute('theme-color', this.themeColor);
    this.viewerService.defineVC(this.hexaViewer.nativeElement);
    await this.viewerService.vc.onModelLoaded();
    this.hasFile = true;
    this.loadingFiles = false;
  }

  async handleModel() {
    this.expordedModel = await this.viewerService.vc.expotModel({
      compressPNG: false,
      downloadFile: false,
      type: ExportFileType.GLB,
    });
  }

  isDisable() {
    if (this.state === this.EXISTING) {
      return !this.generateService.creation || !this.generateService.text;
    } else {
      return !this.generateService.text || !this.hasFile;
    }
  }

  onSelect(item: IReconstructJobUI) {
    this.generateService.creation = item;
    this.generateService.counter++;
  }

  async texture() {
    let glbUrl;
    switch (this.state) {
      case TETURE_GENERATE_TYPE.EXISTING: {
        glbUrl = this.generateService.creation.glb_url;
        break;
      }
      case TETURE_GENERATE_TYPE.FOREIGN: {
        this.utils.notifyUser({
          type: ScreenNotificationType.Info,
          text: 'Exporting model . . .',
        });
        await this.handleModel();
        const blob = new Blob([this.expordedModel.buffer], {
          type: 'application/octet-stream',
        }) as any;
        blob.lastModifiedDate = new Date();
        blob.name = 'scene.glb';
        glbUrl = await this.resumableUploadService.file(blob);
        this.utils.notifyUser({
          type: ScreenNotificationType.Info,
          text: 'Uploading model . . .',
        });
        break;
      }
    }
    const res = (await this.generateService.generateTexture(
      this.generateService.text,
      glbUrl
    )) as IReconstructJobUI;
    res._delayEnter = 1;
    // this.generateService.latestUpdated = res;
    this.utils.notifyUser({
      type: ScreenNotificationType.Info,
      text: 'Texturing model . . .',
    });
  }

  examplePrompt() {
    const prompts = this.enums.getTextureExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.generateService.text = p;
  }

  async onFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingFiles = true;
    this.viewerService.vc.displayFiles(event.srcElement.files);
  }

  ngOnDestroy() {
    this.viewerService.destroy();
  }
}
