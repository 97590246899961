<span class="link text-decoration-none">
  <span class="logo">
    <img src="/assets/images/hexa-logo.svg" alt="hexa logo" width="26" height="28" />
    <img
      src="/assets/images/hexa-logo-text.svg"
      alt="hexa logo text"
      class="text-logo va-super"
      width="65"
      height="16"
    />
  </span>
  <span class="logo-text-wrap va-super">
    <span class="text-gradient uppercase">gen</span>
  </span>
</span>
