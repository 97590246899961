import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IPlaygroundNotification, PlaygroundNotificationType } from '../enums';
import { PixelsService } from '../pixels.service';
import { UtilsService } from '../utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationsService } from '../notifications.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MomentPipe } from '../moment.pipe';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDividerModule, MomentPipe, MatMenuModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public isSidebarOpen: boolean;
  @ViewChild('wrap') wrap: ElementRef;
  private subs: Array<Subscription>;
  private lastTS: number;
  constructor(
    public notificationsService: NotificationsService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private pixels: PixelsService
  ) {
    this.isSidebarOpen = this.notificationsService.getSidebarState();
    this.notificationsService.onSidebarToggle.subscribe((state) => {
      setTimeout(() => {
        this.isSidebarOpen = state;
      });
    });
    this.lastTS = new Date().getTime();
    this.subs = [];
  }

  ngOnInit() {
    this.subs.push(this.notificationsService.onNotification.subscribe(this.onAnnouncement.bind(this)));
    this.subs.push(this.broadcaster.on('popstate').subscribe((e: any) => {
      if (this.isSidebarOpen) {
        // e.preventDefault();
        history.pushState(null, null, window.location.pathname);
        history.pushState(null, null, window.location.pathname);
        this.notificationsService.toggleSidebar(false);
      }
    }));
    this.broadcaster.on('onclick').subscribe(
      (elm: any) => {
        if (this.isSidebarOpen) {
          if (!this.utils.closest(elm, 'app-notifications') && !this.utils.closest(elm, '.toggle-notifications'))
            this.toggleSidebar();
        }
      }
    );
  }

  onAnnouncement(n: IPlaygroundNotification) {
    switch (n.notification_type) {
      case PlaygroundNotificationType.ANNOUNCEMENT: {
        this.showCustomBroadcast(n);
        break;
      }
      // case PlaygroundNotificationType.PAYMENT_APPROVED: {
      //   this.utils.notifyUser({ text: 'Payment was approved', type: NotificationType.Success });
      //   break;
      // }
      // case PlaygroundNotificationType.PAYMENT_CANCELED: {
      //   this.utils.notifyUser({ text: 'Payment was denied', type: NotificationType.Error });
      //   break;
      // }
    }
  }

  getTitleByNotification(n: IPlaygroundNotification) {
    switch (n.notification_type) {
      case PlaygroundNotificationType.ANNOUNCEMENT: {
        return 'Announcement';
      }
      // case PlaygroundNotificationType.JOB_STATUS_CHANGE: {
      //   return 'creation status change';
      // }
      // case PlaygroundNotificationType.ON_RECONSTRUCTION_STATUS_CHANGE: {
      //   return 'on reconstruction status change';
      // }
      // case PlaygroundNotificationType.ON_RECONSTRUCTION_STATUS_CHANGE_2: {
      //   return 'on reconstruction status change 2';
      // }
      // case PlaygroundNotificationType.ROLES_REFRESHED: {
      //   return 'roles refrershes';
      // }
      case PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED: {
        return '3D model is ready';
      }
      // case PlaygroundNotificationType.VERSION_UPDATE: {
      //   return 'version update';
      // }
      // case CreatorsNotificationType.JOB_ASSIGNED: {
      //   return 'You\'ve got a new job';
      // }
      // case CreatorsNotificationType.CUSTOM_BROADCAST: {
      //   return 'Announcement';
      // }
      // case CreatorsNotificationType.JOB_DUE_SOON: {
      //   return 'A job is due soon';
      // }
      // case CreatorsNotificationType.PRIVATE_OFFER: {
      //   return 'You\'ve got a private offer';
      // }
      // case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER: {
      //   return 'A new offer is available';
      // }
      // case CreatorsNotificationType.FEEDBACK_COMMENT: {
      //   return 'A feedback has a new comment';
      // }
      // case CreatorsNotificationType.FEEDBACK_WAITING_FOR_REVIEW: {
      //   return 'A feedback is waiting for your review';
      // }
      // case CreatorsNotificationType.PRIVATE_OFFER_HAS_BEEN_TAKEN: {
      //   return 'A private offer has been taken';
      // }
      // case CreatorsNotificationType.OFFER_HAS_UPDATED_ITS_PRICING: {
      //   return 'An offer has updated its pricing';
      // }
      // case CreatorsNotificationType.ARTIST_HAS_GROUP_PRIVATE_OFFER: {
      //   return 'You\'ve got new private offers';
      // }
      // case CreatorsNotificationType.NEW_JOB_COMMENT: {
      //   return 'You have a new job comment';
      // }
      // case CreatorsNotificationType.SIMILAR_ITEM: {
      //   return 'A new offer might interest you';
      // }
      // case CreatorsNotificationType.PAYMENT_APPROVED: {
      //   return `<b>Payment approved</b> - ${n.notes}`;
      // }
      // case CreatorsNotificationType.PAYMENT_CANCELED: {
      //   return `<b>Payment declined</b> - ${n.notes}`;
      // }
      // case CreatorsNotificationType.TRUST_LEVEL_INCREASED: {
      //   return `Increase: Your Trust Level has increased to ${n.notes}`;
      // }
      // case CreatorsNotificationType.TRUST_LEVEL_DECREASED: {
      //   return `Decrease: Your Trust Level has decreased to ${n.notes}`;
      // }
      // case CreatorsNotificationType.SOURCE_FILE_MATCH: {
      //   return 'Your source files have been validated and your job is approved';
      // }
      // case CreatorsNotificationType.SOURCE_FILE_NO_MATCH: {
      //   return 'One of your source files does not match the approved files, please upload new source files to get paid';
      // }
      // case CreatorsNotificationType.ONE_SOURCE_FILE_MATCH: {
      //   return 'One of your source files has been validated';
      // }
      // case CreatorsNotificationType.ONE_SOURCE_FILE_NO_MATCH: {
      //   return 'One of your source files does not match the approved files, please upload new source files to get paid';
      // }
      default: {
        return PlaygroundNotificationType[n.notification_type];
      }
    }
  }

  toggleSidebar() {
    this.notificationsService.toggleSidebar(!this.notificationsService.getSidebarState());
  }

  // getIcon(n: CreatorsNotification) {
  //   switch (n.notification_type) {
  //     case CreatorsNotificationType.FEEDBACK_RECEIVED: {
  //       return 'assignment_late';
  //     }
  //     case CreatorsNotificationType.JOB_ASSIGNED: {
  //       return 'assignment';
  //     }
  //     case CreatorsNotificationType.CUSTOM_BROADCAST: {
  //       return 'announcement';
  //     }
  //     case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER: {
  //       return 'local_offer';
  //     }
  //     default: {
  //       return 'announcement';
  //     }
  //   }
  // }

  markAsRead(n: IPlaygroundNotification) {
    n.read = true;
    this.notificationsService.markAsRead(n.id);
  }

  showCustomBroadcast(n: IPlaygroundNotification) {
    this.notificationsService.showCustomBroadcast(n);
  }

  action(n: IPlaygroundNotification) {
    switch (n.notification_type) {
      // case PlaygroundNotificationType.FEEDBACK_RECEIVED: {
      //   this.utils.forceRedirectTo('/jobs/feedbacks/' + n.reference_id + '/' + n.artist_user_id + '?ref=FEEDBACK_RECEIVED');
      //   this.markAsRead(n);
      //   this.pixels.sendPixel({
      //     event: 'notificationClickFeedbackReceived',
      //     reference_id: n.id
      //   });
      //   break;
      // }
      case PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED: {
        if (n.reference_id)
          this.utils.forceRedirectTo(`/creations/${n.reference_id}`, {});
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'click',
          click_type: 'model_ready_notification',
          reference_id: n.reference_id,
          notification_id: n.id
        });
        break;
      }
      case PlaygroundNotificationType.ANNOUNCEMENT: {
        // this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'click',
          click_type: 'announcement_notification',
          notification_id: n.id
        });
        this.showCustomBroadcast(n);
        break;
      }
      default: {
        this.markAsRead(n);
        break;
      }
    }
    this.toggleSidebar();
  }

  onScroll(evt: any) {
    let maxPercentage = 0.6;
    let total = evt.target.scrollHeight;
    let current = evt.target.scrollTop;
    let position = current / total;
    if (position > maxPercentage) {
      if (this.utils.isAboveTS(new Date().getTime(), this.lastTS, 2000)) {
        this.notificationsService.fetchMore();
        this.lastTS = new Date().getTime();
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
