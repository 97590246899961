import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { IPlaygroundNotification, IPlaygroundNotificationGroup, IPlaygroundNotificationListResponse, PlaygroundNotificationType, ScreenNotificationType } from './enums';
import { NotificationsWebsocketService } from '../communication/notifications-websocket.service';
import { AuthService } from '../auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { RolesHelperService } from '../auth/roles-helper.service';
import { BodyService } from './body.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from './utils.service';
import { CustomNotificationComponent } from './custom-notification/custom-notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  static ACTION_TYPES = [
    // PlaygroundNotificationType.JOB_STATUS_CHANGE,
    // PlaygroundNotificationType.ON_RECONSTRUCTION_STATUS_CHANGE,
    // PlaygroundNotificationType.ON_RECONSTRUCTION_STATUS_CHANGE_2,
    PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED,
    PlaygroundNotificationType.ANNOUNCEMENT
  ];
  public onNotification: Subject<IPlaygroundNotification>;
  public onSidebarToggle: Subject<boolean>;
  public notifications: Array<IPlaygroundNotification>;
  public notificationsGroups: Array<IPlaygroundNotificationGroup>;
  public unred: number;
  private _didSubscribe: boolean;
  private isSidebarOpen: boolean;
  private limit: number;
  private offset: number;
  private subs: Array<Subscription>;
  private unhandledNotifications: Array<IPlaygroundNotification>;
  private onStateChangeSub: Subscription;
  private onLogin: Subscription;
  private onLogout: Subscription;
  private isInit: boolean;
  private unhandledNotificationsIds: Array<number>;
  constructor(
    private notificationsWebsocket: NotificationsWebsocketService,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private rolesHelper: RolesHelperService,
    private body: BodyService,
    private dialog: MatDialog,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.onNotification = new Subject<IPlaygroundNotification>();
    this.isSidebarOpen = false;
    this.onSidebarToggle = new Subject<boolean>();
    this.isInit = false;
    this.notifications = [];
    this.notificationsGroups = [];
    this.unhandledNotifications = [];
    this.unhandledNotificationsIds = [];
    this.unred = 0;

    this.initPagination();

    if (this.auth.isloggedIn())
      this.subscribe();
    if (this.notificationsWebsocket.isOpen) {
      this.isInit = true;
      this.fetch();
    }
    this.onLogin = this.broadcaster.on('onLogin').subscribe(
      () => {
        this.subscribe();
      }
    );
    this.onLogout = this.broadcaster.on('onLogout').subscribe(
      () => {
        this.isInit = false;
        this.unsubscribe();
        this.notifications = [];
        this.buildGroupes();
      }
    );
  }

  public initPagination() {
    this.limit = 20;
    this.offset = 0;
  }

  public toggleSidebar(state: boolean) {
    this.isSidebarOpen = state;
    // this.body.isBlur = this.isSidebarOpen;
    this.onSidebarToggle.next(this.isSidebarOpen);
  }

  public getSidebarState(): boolean {
    return this.isSidebarOpen;
  }

  public markAsRead(notificationId: number) {
    this.notificationsWebsocket.read(notificationId);
    this.onRead(notificationId);
  }

  public markAllAsRead() {
    this.notificationsWebsocket.readAll();
  }

  public handledNotificationsById(id: number) {
    const notification = this.notifications.find(n => n.id == id);
    if (notification)
      this.handledNotification(notification);
    else
      this.unhandledNotificationsIds.push(id);
  }

  private onRead(id: number) {
    let n = this.notifications.find(n => n.id == id);
    if (n && !n.read) {
      n.read = true;
      if (this.unred > 0)
        this.unred--;
      this.broadcaster.broadcast('deployLinks');
      this.buildGroupes();
    }
  }

  private onReadAll() {
    this.notifications.forEach(n => n.read = true);
    this.unred = 0;
    this.broadcaster.broadcast('deployLinks');
    this.buildGroupes();
  }

  private onAnnouncements(notifications: IPlaygroundNotificationListResponse) {
    this.unred = notifications.unread_count;
    this.pushArray(notifications.items);
    if (this.unhandledNotificationsIds.length) {
      this.unhandledNotificationsIds.forEach(
        id => {
          const n = this.notifications.find(n => n.id == id);
          if (n)
            this.handledNotification(n);
        }
      );
      this.unhandledNotificationsIds = [];
    }
    this.broadcaster.broadcast('deployLinks');
  }

  private pushArray(items: Array<IPlaygroundNotification>) {
    // this.initPagination();
    let n = this.notifications;
    n = n.concat(items);
    this.notifications = n.sort(function (a, b) {
      var c = new Date(a.created_at).getTime();
      var d = new Date(b.created_at).getTime();
      return d - c;
    });
    this.buildGroupes();
  }

  private async onAnnouncement(notification: IPlaygroundNotification) {
    const notificationTypes = await this.auth.fetchNotificationTypes();
    if (!notificationTypes) {
      this.unhandledNotifications.push(notification);
      this.auth.fetchNotificationTypes();
    }
    else
      this.handledNotification(notification);
  }

  private async handledNotification(notification: IPlaygroundNotification) {
    const notificationTypes = await this.auth.fetchNotificationTypes();

    if (!notificationTypes) {
      this.utils.throw('notification types ENUM is undefined!')
    };

    const isNotificationUnread = !notification.read;
    const isNewNotification = !this.notifications.find(n => n.id === notification.id);
    const isKnownActionType = NotificationsService.ACTION_TYPES.find(t => t === notification.notification_type);

    if (isNotificationUnread && isNewNotification && isKnownActionType) {
      this.unred++;
    }

    switch (notification.notification_type) {
      case (PlaygroundNotificationType.ROLES_REFRESHED): {
        this.rolesHelper.fetchRoles();
        break;
      }
      // case PlaygroundNotificationType.JOB_MANAGER_STATUS_CHANGE: {
      //   const data: JobManagerChange = {
      //     managerId: notification.artist_user_id,
      //     status: notification.reference_id,
      //     notes: notification.notes
      //   };
      //   this.broadcaster.broadcast('onJobManagerChange', data);
      //   break;
      // }
      case PlaygroundNotificationType.VERSION_UPDATE: {
        this.utils.notifyUser({
          text: 'A new version of HexaGen is available',
          type: ScreenNotificationType.Info,
          action: 'REFRESH',
          callback: this.refresh,
          scope: this,
          autoDismiss: false
        });
        break;
      }
      default: {
        this.pushArray([notification]);
        this.onNotification.next(notification);
        break;
      }
    }
  }

  private buildGroupes() {
    this.notificationsGroups = [];
    let dictionary = {} as { [id: number]: boolean };
    let last = null as IPlaygroundNotification;
    this.notifications.forEach(item => {
      if (item && !dictionary[item.id]) {
        dictionary[item.id] = true;
        let audit = {} as IPlaygroundNotification;
        // audit.icon = item.icon;
        audit.id = item.id;
        audit.notification_type = item.notification_type;
        // audit.title = item.title;
        audit.created_at = item.created_at;
        audit.read = item.read;
        // audit.reference_id = item.reference_id;
        audit.notes = item.notes;
        // audit.url = item.url;
        // audit.artist_user_id = item.artist_user_id;

        if (last && this.utils.sameDay(last.created_at, item.created_at)) {
          this.notificationsGroups[this.notificationsGroups.length - 1].items.push(audit);
        }
        else {
          this.notificationsGroups.push({
            created_at: item.created_at,
            items: [audit]
          });
        }
        last = item;
      }
    });
  }

  public refresh() {
    // let prefix = '&';
    // if (!window.location.search)
    //   prefix = '?';
    // window.location.href += `${prefix}v=${new Date().getTime()}`;
    window.location.reload();
  }

  public showCustomBroadcast(n: IPlaygroundNotification) {
    this.body.isBlur = true;
    let dialogRef = this.dialog.open(CustomNotificationComponent, {
      data: {
        notificationId: n.id,
        notes: n.notes
      }
    });
    dialogRef.afterClosed().subscribe((id: number) => {
      this.body.isBlur = false;
      if (id) {
        this.markAsRead(id);
      }
    });
  }

  private onNotificationRefreshed() {
    for (let i = this.unhandledNotifications.length - 1; i >= 0; i--) {
      this.handledNotification(this.unhandledNotifications.pop());
    }
  }

  private subscribe() {
    if (this._didSubscribe) return;
    this._didSubscribe = true;
    this.subs.push(this.notificationsWebsocket.onAnnouncement.subscribe(this.onAnnouncement.bind(this)));
    this.subs.push(this.notificationsWebsocket.onAnnouncements.subscribe(this.onAnnouncements.bind(this)));
    this.subs.push(this.notificationsWebsocket.onRead.subscribe(this.onRead.bind(this)));
    this.subs.push(this.notificationsWebsocket.onReadAll.subscribe(this.onReadAll.bind(this)));
    this.subs.push(this.broadcaster.on('notificationRefreshed').subscribe(this.onNotificationRefreshed.bind(this)));

    if (!this.notificationsWebsocket.isOpen) {
      this.onStateChangeSub = this.notificationsWebsocket.onStateChange.subscribe(() => {
        if (this.notificationsWebsocket.isOpen || !this.isInit) {
          this.fetch();
        }
      });
    }
  }

  public fetchMore() {
    this.offset += 20;
    this.fetch();
  }

  private fetch() {
    this.notificationsWebsocket.fetch(this.limit, this.offset, NotificationsService.ACTION_TYPES);
  }

  private unsubscribe() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];

  }

  ngOnDestroy() {
    this.unsubscribe();
    this.onStateChangeSub.unsubscribe();
    this.onLogin.unsubscribe();
    this.onLogout.unsubscribe();
  }
}
