import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ViewerService } from '../viewer.service';
import { ViewerControlsComponent } from '../viewer-controls/viewer-controls.component';
import { UtilsService } from '../utils.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-viewer',
  standalone: true,
  imports: [ViewerControlsComponent, CommonModule],
  templateUrl: './viewer.component.html',
  styleUrl: './viewer.component.scss',
})
export class ViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wrap') wrap: ElementRef;
  @Input('viewerService') viewerService: ViewerService;
  @Input('src') src: string;
  @Input('controls') controls: boolean;
  @Input('grid') grid: boolean;
  private _ready: boolean;
  private _afterInit: boolean;
  private _initOnce: boolean;
  private _selfDefined: boolean;
  constructor(
    private utils: UtilsService
  ) {
    // let test = viewerService as any;
    // test.counter = test.counter ? ++test.counter : 0;
    // console.log(test.counter);
  }

  async ngOnInit() {
    if (this.viewerService)
      this._ready = true;
    else {
      this._selfDefined = true;
      this.viewerService = new ViewerService(this.utils);
      await this.viewerService.injectScript();
      this.viewerService.setViewerUrl(this.src);
      this._ready = true;
      this.init();
    }
  }

  async ngAfterViewInit() {
    this._afterInit = true;
    // this.viewerService.wrap = this.wrap;
    if (this._ready)
      this.init();
  }

  async init() {
    if (this._afterInit && this._ready && !this._initOnce) {
      this._initOnce = true;
      await this.viewerService.setSrc();
      this.wrap.nativeElement.appendChild(this.viewerService.element);
    }
  }

  async ngOnDestroy() {
    if (this._selfDefined && this.viewerService) {
      this.viewerService.destroy();
    }
  }
}
