import { Component } from '@angular/core';
import { ServiceWorkerService } from '../../communication/service-worker.service';
import { StorageService } from 'ng-storage-service';
import { UtilsService } from '../utils.service';
import { MatButtonModule } from '@angular/material/button';
import { PlatformName } from '../constants';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
  selector: 'app-homescreen-teaser',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './homescreen-teaser.component.html',
  styleUrl: './homescreen-teaser.component.scss'
})
export class HomescreenTeaserComponent {
  static LAST_DATE = 'homescreen-teaser-last';
  static WEEK_MS = 604800000;
  public visible: boolean;
  public PlatformName = PlatformName;
  public isBeforeinstallEnabled: boolean;
  constructor(
    private swService: ServiceWorkerService,
    private storage: StorageService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService
  ) {
    this.broadcaster.on('beforeinstallprompt').subscribe(
      () => this.isBeforeinstallEnabled = true
    );
    this.isBeforeinstallEnabled = this.swService.isAddToHomescreenEnable();
  }

  ngOnInit() {
    let date = this.storage.get(HomescreenTeaserComponent.LAST_DATE);
    if (date) {
      date = date * 1;
      if (!isNaN(date)) {
        if (this.utils.isAboveTS(new Date().getTime(), date, HomescreenTeaserComponent.WEEK_MS))
          this.visible = true;
      }
    }
    else
      this.visible = true;
  }

  add() {
    this.swService.addToHomescreen();
    this.hide();
  }

  hide() {
    this.visible = false;
    this.storage.set(HomescreenTeaserComponent.LAST_DATE, new Date().getTime());
  }
}
