import { Component, OnInit } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { CookiesComplianceComponent } from './shared/cookies-compliance/cookies-compliance.component';
import { GlobalsService } from './shared/globals.service';
import { AuthService } from './auth/auth.service';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationsWebsocketService } from './communication/notifications-websocket.service';
import { UtilsService } from './shared/utils.service';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { HomescreenTeaserComponent } from './shared/homescreen-teaser/homescreen-teaser.component';
import { PixelsService } from './shared/pixels.service';
import { allowUnAuthPaths } from './shared/constants';
import { StorageService } from 'ng-storage-service';
import { CommonModule } from '@angular/common';
import { BodyService } from './shared/body.service';

// declare var google: any;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatProgressBar,
    HeaderComponent,
    CookiesComplianceComponent,
    HomescreenTeaserComponent,
    CommonModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public isLogin: boolean;
  public env = environment;
  constructor(
    private auth: AuthService,
    public globals: GlobalsService,
    private broadcaster: BroadcasterService,
    private router: Router,
    private route: ActivatedRoute,
    // Do not remove notificationsWebsocketService as it won't start and we will not get notifications
    public notificationsWebsocketService: NotificationsWebsocketService,
    private utils: UtilsService,
    private meta: Meta,
    private pixels: PixelsService,
    private storage: StorageService,
    public body: BodyService
  ) {
    this.refreshIsLogin();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.refreshIsLogin()
        var snapshot = this.route.snapshot;
        var activated = this.route.firstChild;
        if (activated != null) {
          while (activated != null) {
            snapshot = activated.snapshot;
            activated = activated.firstChild;
          }
        }
        this.pixels.sendPixel({
          event: 'pageView',
        });
        // this.broadcaster.broadcast('setCurrentRouteName', snapshot.data['stateName']);
        // this.broadcaster.broadcast('onNavigationSnapshotChange', snapshot);
      }
    });
    if (!environment.production)
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    document.addEventListener(
      'visibilitychange',
      this.onVisibilityChange.bind(this),
      false
    );
  }

  async ngOnInit() {
    const favIcon: HTMLLinkElement = document.querySelector('link[rel=icon]');
    favIcon.href = environment.favIcon;
    if (await this.auth.isloggedIn()) {
      this.broadcaster.broadcast('onLogin', this.auth.user);
    } else {
      // const d = document.createElement('div');
      // d.id = 'g_id_onload';
      // d.setAttribute('data-client_id', environment.googleData.client_id);
      // d.setAttribute('data-response_type', 'code');
      // d.setAttribute('data-state', encodeURIComponent(
      //   environment.googleData.state
      // ));
      // d.setAttribute('data-scope', 'openid email profile');
      // d.setAttribute('data-redirect_uri', encodeURIComponent(
      //   environment.googleData.redirect_uri
      // ));
      // d.setAttribute('data-prompt', 'consent');
      // d.setAttribute('data-include_granted_scopes', 'true');
      // d.setAttribute('data-access_type', 'offline');
      // document.body.appendChild(d);
      // await this.utils.loadScript('https://accounts.google.com/gsi/client');
      // google.accounts.id.initialize({
      //   client_id: environment.googleData.client_id,
      //   response_type: 'code',
      //   // state: encodeURIComponent(environment.googleData.state),
      //   state: environment.googleData.state,
      //   scope: 'openid email profile',
      //   // context: 'signin',
      //   // redirect_uri: encodeURIComponent(
      //   //   environment.googleData.redirect_uri
      //   // ),
      //   redirect_uri: environment.googleData.redirect_uri,
      //   prompt: 'consent',
      //   include_granted_scopes: 'true',
      //   access_type: 'offline',
      //   cancel_on_tap_outside: false,
      //   callback: (x: any) => {
      //     debugger;
      //   },
      // });
      // google.accounts.id.prompt((notification: any) => {
      //   debugger;
      //   if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      //     // try next provider if OneTap is not displayed or skipped
      //   }
      // });
    }
    const gclid = this.utils.getUrlParam(location.href, 'gclid');
    if (gclid && gclid !== location.href) this.storage.set('gclid', gclid);
    // Fix getUrlParam bug
    if (this.storage.get('gclid') === location.href)
      this.storage.remove('gclid');
    const affiliate_id = this.utils.getUrlParam(window.location.search, 'ref');
    if (affiliate_id) this.storage.set('reference_id', affiliate_id);
  }

  onLogout() {
    this.refreshIsLogin();
  }

  async refreshIsLogin() {
    this.isLogin = await this.auth.isloggedIn();
    if (
      !this.isLogin &&
      !allowUnAuthPaths.find((p) => window.location.pathname.indexOf(p) > -1)
    )
      this.router.navigate(['/login']);
  }

  onVisibilityChange() {
    if (!this.utils.isDocHidden())
      this.broadcaster.broadcast('onDocumentFocus');
  }
}
