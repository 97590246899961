<div [ngClass]="{ 'full scrollbar': !partial }">
  <h1 class="h1 underline" *ngIf="!partial">Privacy Notice</h1>
  <a
    mat-menu-item
    class="pos-abs back"
    routerLink="/login"
    *ngIf="!partial && !isloggedIn"
    >back to login</a
  >
  <iframe
    title="privacy"
    src="https://cdn.hexa3d.io/hotlink-ok/policies/hexa3d-privacy.html?&theme=hexagen"
    frameborder="0"
  ></iframe>
</div>
