<div class="wrap component-wrap">
    <section>
        <p>option 1</p>
        (1$ is equal to 40 tokens) Pay 10$ and get 400 tokens. (1 model will cost 10$ => 10$ per model)
    </section>
    <section>
        <p>option 2</p>
        (1$ is equal to 45 tokens) Pay 100$ and get 4,500 tokens. (11.25 models will cost 100$ => 8.8$ per model)
    </section>
    <section>
        <p>option 3</p>
        (1$ is equal to 50 tokens) Pay 1000$ and get 50,000 tokens. (125 models will cost 1,000$ => 8$ per model)
    </section>
</div>