<div class="wrap disp-flex">
  <div class="links disp-flex row mobile-col">
    <div>
      <app-hexa-logo></app-hexa-logo>
    </div>
    <div class="grow"></div>
    <div class="links-inner disp-flex row flex-def">
      <div>
        <h4 class="uppercase ttl-xs">main pages</h4>
        <p>
          <a class="ttl-xxs capitalize" [routerLink]="['/']">homepage</a>
        </p>
        <p>
          <a class="ttl-xxs capitalize" [routerLink]="['/pricing']">pricing</a>
        </p>
        <!-- <p>
          <a class="ttl-xxs capitalize" [routerLink]="['/marketplace']">marketplace</a>
        </p> -->
      </div>
      <div>
        <h4 class="uppercase ttl-xs">policy</h4>
        <p>
          <a class="ttl-xxs capitalize" [routerLink]="['/about']">about</a>
        </p>
        <p>
          <a class="ttl-xxs capitalize" [routerLink]="['/terms']">terms and conditions</a>
        </p>
        <p>
          <a class="ttl-xxs capitalize" [routerLink]="['/privacy']">privacy policy</a>
        </p>
      </div>
    </div>
  </div>
</div>
