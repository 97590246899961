<div class="controls" [ngClass]="{ 'view-only': viewOnly }">
  <div class="buttons mobile-small disp-inline-flex">
    <div class="buttons-group">
      <div class="button-wrap">
        <button
          type="button"
          mat-raised-button
          class="btn btn-ghost no-marg-img"
          aria-label="standard"
          [ngClass]="{ active: viewerService.standard }"
          (click)="viewerService.standard = !viewerService.standard"
          matTooltip="texture"
        >
          <img
            src="/assets/images/texture.svg"
            alt="texture"
            [ngClass]="{ invert: viewerService.standard }"
          />
        </button>
      </div>
      <div class="button-wrap">
        <button
          type="button"
          mat-raised-button
          class="btn btn-ghost no-marg-img"
          aria-label="wireframe"
          [ngClass]="{ active: viewerService.wireframe }"
          (click)="viewerService.wireframe = !viewerService.wireframe"
          matTooltip="wireframe"
        >
          <img
            src="/assets/images/mesh.svg"
            alt="wireframe"
            [ngClass]="{ invert: viewerService.wireframe }"
          />
          <!-- geometry.svg -->
        </button>
      </div>

      <div class="button-wrap">
        <button
          type="button"
          mat-raised-button
          class="btn btn-ghost no-marg-img"
          aria-label="solid"
          [ngClass]="{ active: viewerService.matcap }"
          (click)="viewerService.matcap = !viewerService.matcap"
          matTooltip="solid"
        >
          <img
            src="/assets/images/solid.svg"
            alt="material"
            [ngClass]="{ invert: viewerService.matcap }"
          />
          <!-- wireframe.svg -->
        </button>
      </div>

      <div class="button-wrap">
        <button
          type="button"
          mat-raised-button
          class="btn btn-ghost no-marg-img"
          aria-label="UV map"
          [ngClass]="{ active: viewerService.uv }"
          (click)="viewerService.uv = !viewerService.uv"
          matTooltip="UV map"
        >
          <img
            src="/assets/images/uv.svg"
            alt="UV map"
            [ngClass]="{ invert: viewerService.uv }"
          />
        </button>
      </div>
    </div>

    <div class="button-wrap">
      <button
        type="button"
        mat-raised-button
        class="btn btn-ghost no-marg-img"
        aria-label="share"
        (click)="share()"
        matTooltip="share"
      >
        <img src="/assets/images/share.svg" alt="share" />
      </button>
    </div>
    <!-- @if (roles.isRoleLogedin('Super User')) { -->
     <!-- @if (isQA) {
      <div class="button-wrap">
        <button
          type="button"
          mat-raised-button
          class="btn btn-c retexture"
          aria-label="retexture"
          (click)="renderBG()"
        >
          <span class="hidden-mobile">Render Image</span>
        </button>
      </div>
    } -->
    @if (viewerService.glbUrl) {
    <div class="button-wrap">
      <button
        type="button"
        mat-raised-button
        class="btn btn-ghost no-marg-img"
        aria-label="download"
        [disabled]="!viewerService.canDownlopad"
        (click)="download()"
        matTooltip="download"
      >
        <img src="/assets/images/download.svg" alt="download" />
      </button>
    </div>
    <div class="button-wrap">
      <button
        type="button"
        mat-raised-button
        class="btn btn-c retexture no-marg-img-mobile"
        aria-label="retexture"
        (click)="aiTexture()"
      >
        <img src="/assets/images/ai-texture.svg" alt="text" class="va-middle" />
        <span class="hidden-mobile">Retexture</span>
      </button>
    </div>
    }
  </div>
</div>
