<div class="wrap disp-flex mobile-col-reverse">
  <div class="prompt-wrap view-min-height">
    <div class="disp-flex col prompt-inner">
      <app-ai-texture-prompt></app-ai-texture-prompt>
      <!-- @if (generateService.latestUpdated) {
      <div class="component-wrap">
        <h3 class="txt-xxs bold capitalize-fl no-marg flex-1 hidden-mobile space-bottom-xs-mobile">
          current job
        </h3>
        <app-creation-card
          (onSelect)="onSelect($event)"
          [item]="generateService.latestUpdated"
          [counter]="generateService.counter"
        ></app-creation-card>
      </div>
      } -->
    </div>
  </div>
  <div
    class="flex-1 view-min-height creation-wrap"
    [ngClass]="{
      empty: !generateService.creation
    }"
  >
    @if(generateService.creation) {
    <app-view-creation
      class="disp-block h-100"
      [creation]="generateService.creation"
      (onPreview)="setPreview($event)"
    ></app-view-creation>
    }
  </div>
</div>
