import { Injectable, Injector } from '@angular/core';
import { FieldType, InfiniteScroll } from '../shared/infinite-scroll';
import { KeyValuePair } from '../shared/enums';
import { ICreationsFilterOptions } from './creations';
import { UtilsService } from '../shared/utils.service';
import { IReconstructJobUI } from './generate';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CreationsService extends InfiniteScroll {
  static DEFAULT_FILTER = {
    limit: 20,
    offset: 0,
    is_archived: false,
    action_id: [],
    // is_desc: true,
    // order_by: 'session_id',
  } as ICreationsFilterOptions;
  public fields: Array<KeyValuePair>;
  public searchFields: Array<KeyValuePair>;
  public counter: number;
  constructor(private utils: UtilsService, private injector: Injector) {
    super(
      {
        chunkSize: CreationsService.DEFAULT_FILTER.limit,
        graphFunction: 'allReconstructionJobs',
        defaults: {
          is_desc: CreationsService.DEFAULT_FILTER.is_desc,
          order_by: CreationsService.DEFAULT_FILTER.order_by,
        } as ICreationsFilterOptions,
      },
      injector
    );
    this.counter = 0;
    this.fieldTypes = {
      action_id: FieldType.NumbersArray,
    };
    this.initFilter();
  }

  override afterItemsChanged(forceRefresh: boolean) {
    super.afterItemsChanged(forceRefresh);
    let index = 0;
    this.items.forEach((item: IReconstructJobUI) => {
      if (typeof item._delayEnter !== 'number') {
        item._delayEnter = item._delayEnter || ++index * 50;
      }
    });
  }

  override initFilter() {
    super.initFilter();
    this.ignoreChanges = true;
    this.filter.addControl(
      'action_id',
      new FormControl(CreationsService.DEFAULT_FILTER.action_id)
    );
    this.filter.addControl(
      'is_archived',
      new FormControl(CreationsService.DEFAULT_FILTER.is_archived)
    );
    this.filter.removeControl('order_by');
    this.filter.removeControl('is_desc');
    this.ignoreChanges = false;
    this.lastQuery = this.getSearchQuery();
  }

  removeItem(item: IReconstructJobUI) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].id === item.id) {
        this.items.splice(i, 1);
        this.itemsCount--;
        this.scrollLimit--;
        if (this.scrollLimit < 0) this.scrollLimit = 0;
        this.scrollOffset--;
        if (this.scrollOffset < 0) this.scrollOffset = 0;
        break;
      }
    }
  }

  copyCreation(src: IReconstructJobUI, dest: IReconstructJobUI) {
    dest.status = src.status;
    dest.viewer_url = src.viewer_url;
    dest.preview = src.preview;
    dest.updated_at = src.updated_at;
    dest.start_time = src.start_time;
  }
}
