<div
  class="wrap disp-flex col align-items-center text-center justify-content-center h-100"
>
  @if (item.status === RECONSTRUCT_JOB_STATUS.Queued) {
  <h3 class="ttl-md">Waiting in queue...</h3>
  <div class="desc txt-xxs">
    This may take a few minutes, you can wait here or
    <br />
    generate another model.
    <br />
    We’ll notify you when its ready.
  </div>
  } @else if (item.status === InProgress) {
  <h3>Generating model... {{ percentage }}%</h3>
  <div class="desc txt-xxs">
    This may take a few minutes, you can wait here or generate another model.
    <br />
    We’ll notify you when its ready.
  </div>
  } @else if (item.status === RECONSTRUCT_JOB_STATUS.Failed) {
  <h3 class="ttl-md">Operation failed</h3>
  <div class="desc txt-xxs">
    We have refunded the credits
    <br />
    used for this operation
  </div>
  }
</div>
