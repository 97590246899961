<div class="wrap text-center col">
  <div class="price ttl-hero space-bottom-lg">
    ${{ details.price | number }}
  </div>
  <div class="desc space-bottom-2xl">
    {{ details.desc }}
  </div>
  <div>
    <button type="button" mat-raised-button class="btn btn-c w-100" (click)="buy()">
      buy
    </button>
  </div>
</div>
