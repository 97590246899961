<div
  class="main-wrap pos-rel"
  [ngClass]="{ 'popular': details.isPopular, 'bg-rel': cliced }"
>
  <div class="wrap text-start disp-flex col">
    <h3 class="ttl-xs capitalize-fl">{{ details.name }}</h3>
    <p class="ttl-hero pos-rel">
      {{ details.price }}$ @if (hasOrgPrice) {
      <span class="pos-abs txt-sm org-price">
        <div class="txt-sm bold line-through gray-50-color">
          {{ details.orgPrice }}$
        </div>
        <div>Per month</div>
      </span>
      }
    </p>
    <div class="desc txt-sm">{{ details.desc }}</div>
    <div class="attributes">
      <ul>
        @for (item of details.attributes; track $index) {
        <li class="capitalize-fl">{{ item }}</li>
        }
      </ul>
    </div>
    <div>
      <button
        type="button"
        mat-raised-button
        class="btn w-100 btn-c"
        [ngClass]="{ active: details.isCurrent }"
        [disabled]="details.isCurrent"
        (click)="pay()"
      >
        @if (details.isCurrent) {
        <img src="assets/images/v.svg" alt="check" class="va-middle invert" /> Active
        plan } @else { Join {{ details.name }}
        }
      </button>
    </div>
  </div>
</div>
