<app-balls [has-scroll]="true"></app-balls>
<div class="wrap component-wrap" [ngClass]="screen + '-wrap'">
  <div class="inner vertical-align text-center">
    <form
      [formGroup]="loginForm"
      (ngSubmit)="signup()"
      novalidate
      *ngIf="screen === 'signup'"
      class="signup"
    >
      <div class="disp-flex col align-items-center">
        <div class="flex-def" class="screen-name">
          Hello
          <span
            *ngIf="
              loginForm.controls['name'] && loginForm.controls['name'].value
            "
            >{{ loginForm.controls["name"].value }}!</span
          >
          <div class="sub-text">
            Thank you for joining us,<br />
            just a few more steps to create your account.
          </div>
        </div>
        <div fxFill class="signup-pass">
          <mat-form-field fxFlexFill>
            <mat-label>Password</mat-label>
            <input
              placeholder="Password"
              [type]="isShowPassword ? 'text' : 'password'"
              matInput
              formControlName="password"
              id="input_1"
              [pattern]="StrongPasswordRegx"
            />
            <mat-hint
              *ngIf="
                loginForm.hasError('required', 'password') && loginForm.dirty
              "
              align="start"
              >password is required</mat-hint
            >
            <mat-hint
              *ngIf="
                loginForm.hasError('pattern', ['password']) && loginForm.dirty
              "
              align="start"
              >must contain special characters, numbers, small letters, capital
              letters and at least 8 characters long</mat-hint
            >
            <span
              [ngClass]="{
                hidden: !loginForm?.controls['password']?.value?.length
              }"
              class="eye-password material-icons pointer"
              (click)="toggShowPassword()"
            >
              {{ isShowPassword ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
        <div fxFill class="signup-confirm-pass">
          <mat-form-field
            fxFlexFill
            hideRequiredMarker
            [ngClass]="{
              'form-field-invalid mat-form-field-invalid ng-invalid':
                !isPasswordsMatch() && loginForm.dirty
            }"
          >
            <mat-label>Confirm Password</mat-label>
            <input
              placeholder="Confirm Password"
              [type]="isShowConfirmPassword ? 'text' : 'password'"
              matInput
              formControlName="confirm_password"
              required
            />
            <mat-hint
              *ngIf="!isPasswordsMatch() && loginForm.dirty"
              align="start"
              >password don't match</mat-hint
            >
            <span
              [ngClass]="{
                hidden: !loginForm?.controls['confirm_password']?.value?.length
              }"
              class="eye-password material-icons pointer"
              (click)="isShowConfirmPassword = !isShowConfirmPassword"
            >
              {{ isShowConfirmPassword ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
        <div fxFill>
          <div class="register text-center">
            Already a member?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div fxFill>
          <button
            type="submit"
            mat-raised-button
            color="accent"
            class="btn btn-a submit center-me min-button-width"
          >
            sign up
          </button>
        </div>
      </div>
    </form>
    <form
      [formGroup]="loginForm"
      (ngSubmit)="login()"
      novalidate
      *ngIf="screen === 'login'"
    >
      <div class="disp-flex col align-items-center login-wrap">
        <div class="flex-def">
          <h1 class="uppercase ttl-sm">login</h1>
        </div>
        <app-sso-options class="w-100"></app-sso-options>
        <!-- <div>
          <app-or-separator>or</app-or-separator>
        </div>
        <div class="flex-fill">
          <div>
            <mat-form-field fxFill hideRequiredMarker class="t-bg w-100">
              <mat-label>Email</mat-label>
              <input
                placeholder="Email"
                matInput
                type="email"
                formControlName="email"
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                required
                id="input_0"
              />
              <mat-hint
                *ngIf="
                  loginForm.hasError('required', 'email') && loginForm.dirty
                "
                align="start"
                >e-mail is required</mat-hint
              >
              <mat-hint
                *ngIf="
                  loginForm.hasError('pattern', 'email') && loginForm.dirty
                "
                align="start"
                >e-mail not valid
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="pos-rel flex-fill">
          <mat-form-field class="t-bg w-100">
            <mat-label>Password</mat-label>
            <input
              placeholder="Password"
              [type]="isShowPassword ? 'text' : 'password'"
              matInput
              formControlName="password"
              id="input_1"
            />
            <mat-hint
              *ngIf="
                loginForm.hasError('required', 'password') && loginForm.dirty
              "
              align="start"
              >password is required</mat-hint
            >
            <span
              [ngClass]="{
                hidden: !loginForm?.controls['password']?.value?.length
              }"
              class="eye-password material-icons pointer"
              (click)="toggShowPassword()"
            >
              {{ isShowPassword ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>

        <div>
          <div
            class="pointer txt-xxs underline uppercase forgot-password"
            (click)="toggleFP('forgotpassword')"
          >
            forgot password?
          </div>
        </div>

        <div class="flex-fill">
          <button
            type="submit"
            mat-raised-button
            color="accent"
            class="btn btn-a w-100 big"
          >
            login
          </button>
        </div>
        <div class="flex-def">
          <div class="uppercase space-top txt-sm">
            DON’T HAVE AN ACCOUNT?
            <a class="link" routerLink="/register">SIGN UP</a>
          </div>
        </div> -->
        <div class="w-100 terms-wrap">
          <a
            class="float-dir pointer privacy link txt-xxs bold"
            (click)="showPrivacy()"
            href="javascript:void(0)"
            >Privacy policy</a
          >
          <a
            class="float-op-dir pointer link txt-xxs bold"
            (click)="showTerms()"
            href="javascript:void(0)"
            >Terms & conditions</a
          >
        </div>
      </div>
    </form>
    <form
      [formGroup]="fpForm"
      (ngSubmit)="resetPassword()"
      novalidate
      *ngIf="screen === 'forgotpassword'"
      class="forgotpassword"
    >
      <div class="disp-flex col align-items-center">
        <div class="flex-def">
          <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span
            ><span>Back</span>
          </div>
        </div>
        <div class="reset-password" fxFlex>
          Reset Password
          <div class="sub-text">
            Insert your email and we will send you a recovery link
          </div>
        </div>
        <div fxFill>
          <mat-form-field fxFill hideRequiredMarker>
            <mat-label>Email</mat-label>
            <input
              placeholder="Email"
              matInput
              type="email"
              formControlName="email"
              pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              required
            />
            <mat-hint
              *ngIf="fpForm.hasError('required', 'email') && fpForm.dirty"
              >e-mail is required</mat-hint
            >
            <mat-hint
              *ngIf="fpForm.hasError('pattern', 'email') && fpForm.dirty"
              >e-mail not valid</mat-hint
            >
          </mat-form-field>
        </div>
        <div fxFill>
          <button
            type="submit"
            [disabled]="
              !fpForm.dirty ||
              fpForm.hasError('required', 'email') ||
              fpForm.hasError('pattern', 'email')
            "
            mat-raised-button
            class="btn btn-a"
          >
            send recovery link
          </button>
        </div>
      </div>
    </form>
    <form
      [formGroup]="loginForm"
      (ngSubmit)="signup()"
      novalidate
      *ngIf="screen === 'reset'"
      class="reset scrollbar"
    >
      <div class="disp-flex col align-items-center">
        <div class="flex-def">
          <!-- <div class="back-button pointer" (click)="goBack()">
              <span class="material-icons back-icon">arrow_back_ios</span><span>Back</span>
            </div> -->
        </div>
        <div class="reset-password" fxFlex>
          Reset Password
          <div class="sub-text">
            <!-- Your new password must be different from previous used passwords-->
          </div>
        </div>
        <div fxFill class="signup-pass">
          <mat-form-field fxFlexFill>
            <mat-label>New Password</mat-label>
            <input
              placeholder="New Password"
              [type]="isShowPassword ? 'text' : 'password'"
              matInput
              formControlName="password"
              id="input_1"
              pattern="^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$"
            />
            <mat-hint
              *ngIf="
                loginForm.hasError('required', 'password') && loginForm.dirty
              "
              align="start"
              >Password is required</mat-hint
            >
            <mat-hint
              *ngIf="
                loginForm.hasError('pattern', ['password']) && loginForm.dirty
              "
              align="start"
              >Must contain special characters, numbers, small letters, capital
              letters and at least 8 characters long</mat-hint
            >
            <span
              [ngClass]="{
                hidden: !loginForm?.controls['password']?.value?.length
              }"
              class="eye-password material-icons pointer"
              (click)="toggShowPassword()"
            >
              {{ isShowPassword ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
        <div class="flex-def">
          <mat-form-field
            fxFlexFill
            hideRequiredMarker
            [ngClass]="{
              'form-field-invalid mat-form-field-invalid ng-invalid':
                !isPasswordsMatch() && loginForm.dirty
            }"
          >
            <mat-label>Confirm Password</mat-label>
            <input
              placeholder="Confirm Password"
              [type]="isShowConfirmPassword ? 'text' : 'password'"
              matInput
              formControlName="confirm_password"
              required
            />
            <mat-hint
              *ngIf="!isPasswordsMatch() && loginForm.dirty"
              align="start"
              >Password don't match</mat-hint
            >
            <span
              [ngClass]="{
                hidden: !loginForm?.controls['confirm_password']?.value?.length
              }"
              class="eye-password material-icons pointer"
              (click)="isShowConfirmPassword = !isShowConfirmPassword"
            >
              {{ isShowConfirmPassword ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
        <div class="flex-def">
          <div class="register text-center">
            Changed your mind?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div fxFill>
          <button
            type="submit"
            mat-raised-button
            class="btn btn-a center-me min-button-width fp-submit2"
            [disabled]="!fpForm.valid || !loginForm.dirty"
          >
            update password
          </button>
          <div class="hint text-center">
            Changed your mind?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div [hidden]="!fPmeg" class="hint fp-msg pos-abs">{{ fPmeg }}</div>
      </div>
    </form>
    <form
      [formGroup]="fpForm"
      (ngSubmit)="confirmReset()"
      novalidate
      *ngIf="screen === 'update'"
    >
      <div class="disp-flex col align-items-center">
        <div class="reset-password" fxFlex>Reset QR code</div>
        <div class="centertext" fxFill>
          <div>Your request to reset the QR code was approved.</div>
          <div>
            When confirming, you will be required to scan a new QR code once
            signing in.
          </div>
        </div>
        <div fxFill>
          <button
            type="submit"
            mat-raised-button
            class="btn btn-a center-me min-button-width fp-submit"
            [disabled]="!fpForm.valid"
          >
            confirm reset
          </button>
          <div class="hint text-center">
            Changed your mind?
            <a routerLink="/login">Sign in</a>
          </div>
        </div>
        <div fxFill></div>
        <div [hidden]="!fPmeg" class="hint fp-msg pos-abs">{{ fPmeg }}</div>
      </div>
    </form>
    <form
      [formGroup]="fpForm"
      (ngSubmit)="enterCode()"
      novalidate
      *ngIf="screen === 'qr'"
    >
      <div class="disp-flex col align-items-center">
        <div class="flex-def">
          <!-- <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span
            ><span>Back</span>
          </div> -->
        </div>
        <div class="flex-def">
          <h1 class="ttl-sm uppercase">two factor authenticator</h1>
        </div>
        <div class="sso-wrap w-100"></div>
        <div class="text-center flex-def">
          <img class="qr-img" [src]="qrImg" alt="QR Code" />
        </div>
        <div class="text-center txt-sm flex-def">
          Scan the QR code with an authentication app on your phone.
        </div>
        <div fxFill>
          <button
            type="submit"
            mat-raised-button
            class="btn btn-a big center-me qr-submit"
          >
            next
          </button>
          <div class="hint text-center link pointer">
            <div (click)="qrPopup(1)">Can't scan the QR code?</div>
          </div>
        </div>
        <!-- <div [hidden]="!fPmeg" class="hint fp-msg pos-abs">{{fPmeg}}</div> -->
      </div>
    </form>
    <form
      [formGroup]="loginForm"
      (ngSubmit)="sendCode()"
      novalidate
      *ngIf="screen === 'qrcode'"
    >
      <div class="disp-flex col align-items-center">
        <div class="flex-def">
          <!-- <div class="back-button pointer" (click)="goBack()">
            <span class="material-icons back-icon">arrow_back_ios</span
            ><span>Back</span>
          </div> -->
        </div>
        <div fxFlex class="screen-title text-center">
          Enter the code that appears in the authentication app on your phone
          <div class="sub-title txt-xxs space-top">
            The code is valid for the next 30 seconds
          </div>
        </div>
        <!-- <div class="flex-fill pos-rel qr-code-input-wrap">
          <div class="qr-code-input-bg pos-abs"></div>
          <input
            (keyup)="qrKeyup($event)"
            max="999999"
            maxlength="6"
            class="hide-placeholder qr-code-input"
            placeholder="Type Code"
            type="number"
            formControlName="code"
            autofocus
          />
        </div> -->
        <div class="flex-fill pos-rel space-top">
          <div class="space-bottom-sm txt-xxs capitalize-fl text-start">
            Type code
          </div>
          <mat-form-field class="hide-placeholder w-100">
            <input
              placeholder="Type Code"
              matInput
              maxlength="6"
              formControlName="code"
              autofocus
              (keyup)="qrKeyup($event)"
            />
          </mat-form-field>
        </div>
        <div fxFill>
          <button
            mat-raised-button
            class="btn btn-a big center-me"
            [disabled]="!fpForm.valid"
          >
            Sign In
          </button>
        </div>
        <div (click)="qrPopup(2)" class="hint link pointer space-top">
          Didn't get a code?
        </div>
      </div>
    </form>
    <form novalidate *ngIf="screen === 'mobile'">
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxFlex>
          <img
            alt="hexa 3d"
            class="logo"
            src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg"
          />
        </div>
        <div fxFlex class="screen-title text-center">
          A Device Change Is Needed
        </div>
        <div>
          <div class="centertext" fxFill>
            <div>You can only access this email via desktop.</div>
          </div>
        </div>
      </div>
    </form>
    <!-- <div class="down">
      <span
        [ngClass]="{
          grayout:
            screen === 'forgotpassword' ||
            screen === 'signup' ||
            screen === 'reset'
        }"
        class="pointer"
        (click)="
          toggleFP(screen !== 'forgotpassword' ? 'forgotpassword' : 'login')
        "
      >
        {{
          screen === "forgotpassword" || screen === "qr" || screen === "reset"
            ? "Reset Password"
            : "Forgot password?"
        }}
      </span>
      <span class="pointer privacy" (click)="showPrivacy()"
        >Privacy policy</span
      >
      <span class="float-op-dir pointer" (click)="showTerms()"
        >Terms & conditions</span
      >
    </div> -->
  </div>
</div>
