import { Injectable } from '@angular/core';
import { GraphqlService } from '../../communication/graphql.service';

@Injectable()
export class TransactionsResolve {
  constructor(private gql: GraphqlService) {}

  resolve() {
    return this.gql.allReconstructionTransactions();
  }
}
