<div
  class="wrap h-100 pos-rel"
  #wrap
  [ngClass]="{ 'show-grid': grid, 'show-controls': controls, 'custom-bg': viewerService.style['background-image'] }"
  [ngStyle]="viewerService.style"
>
  @if (viewerService) {
  <app-viewer-controls
    [viewerService]="viewerService"
    class="pos-abs horizontal-align"
  ></app-viewer-controls>
  }
</div>
