<div class="wrap h-100 pos-rel">
  <div class="h-100 disp-flex row inner-wrap">
    <hexa-viewer
      #hexaViewer
      class="w-100"
      embed-loader="true"
      autorotate="true"
      auto-adjust="true"
      [ngClass]="{ 'disp-none': !!glbURL }"
    ></hexa-viewer>
    <div class="disp-flex h-100 col prompt-wrap w-100">
      <div class="disp-flex ip-wrap">
        <span class="txt-xxs bold">Re-texture prompt</span>
        <a
          href="javascript:void(0)"
          class="align-end-flex link txt-xxs bold"
          (click)="examplePrompt()"
          >example prompt</a
        >
      </div>
      <mat-form-field class="w-100 ta-wrap flex-1">
        <textarea
          rows="6"
          [(ngModel)]="text"
          placeholder="Insert re-texture prompt here..."
          matInput
        ></textarea>
      </mat-form-field>
      <button
        class="btn btn-a space-bottom-md"
        mat-raised-button
        [disabled]="isDisabled()"
        (click)="texture()"
      >
        <img
          src="/assets/images/ai-texture.svg"
          alt="ai-texture"
          class="va-text-top"
        />
        Re-texture
      </button>
      <app-cost-est [cost]="cost" [est]="120"></app-cost-est>
    </div>
  </div>
</div>
