<!-- @switch (pricingPeriod) {
    @case (MONTHLY) { MONTHLY }
    @case (YEARLY) { YEARLY }
} -->
@if (plans) {
<div class="disp-flex row mobile-col items">
  @for (item of plans; track $index) {
  <div class="flex-def">
    <app-pricing-card class="disp-block h-100" [details]="item"></app-pricing-card>
  </div>
  }
</div>
}
