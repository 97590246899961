import { Injectable } from '@angular/core';
import { Software, SoftwareQueryData, SoftwareType } from '../product/product';
import { GraphqlService } from '../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class SoftwaresService {
  private cachedSoftwares: Array<Software>;
  private cachedSoftwaresDictionary: { [id: number]: Software };
  private _stock: Array<Function>;
  constructor(private gql: GraphqlService, private utils: UtilsService) {
    this._stock = [];
  }

  async fetch(): Promise<{ [id: number]: Software }> {
    return new Promise(async (resolve, reject) => {
      if (this.cachedSoftwares) {
        resolve(this._getSoftwaresDictionary());
        return;
      }
      if (this._stock.length > 0) {
        this._stock.push(resolve);
        return;
      }
      this._stock.push(resolve);
      this.cachedSoftwares = (
        (await this.utils.observableToPromise(
          this.gql.softwares()
        )) as ApolloQueryResult<SoftwareQueryData>
      ).data.software_enum;
      this._stock.forEach((f) => f(this._getSoftwaresDictionary()));
      this._stock = [];
    });
  }

  private _getSoftwaresDictionary(): { [id: number]: Software } {
    if (this.cachedSoftwaresDictionary) return this.cachedSoftwaresDictionary;
    if (!this.cachedSoftwares) return null;
    this.cachedSoftwaresDictionary = {};
    this.cachedSoftwares.forEach(
      (s) => (this.cachedSoftwaresDictionary[s.id] = s)
    );
    return this.cachedSoftwaresDictionary;
  }
}
