import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IPaymentPackage, IReconstructionTransaction } from '../../pricing/pricing';
import { MomentPipe } from '../../shared/moment.pipe';
import { EnumsService } from '../../shared/enums.service';
import { UsageService } from '../usage.service';
import { ScrollHelper } from '../../shared/scroll-helper';
import { UtilsService } from '../../shared/utils.service';
import { PaddleService } from '../../payments/paddle.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-usage',
  standalone: true,
  imports: [MomentPipe, DecimalPipe],
  templateUrl: './usage.component.html',
  styleUrl: './usage.component.scss',
})
export class UsageComponent implements OnInit {
  @Input() transactions: Array<IReconstructionTransaction>;
  public actionsDictionary: { [id: number]: string };
  private scrollHelper: ScrollHelper;
  private productsDictionary: { [id: string]: IPaymentPackage };
  @ViewChild('scroll') public scroll: ElementRef;
  constructor(
    private enums: EnumsService,
    public usageService: UsageService,
    private utils: UtilsService,
    private paddleService: PaddleService
  ) {
    this.productsDictionary = {};
    this.actionsDictionary = this.enums.getActionsDictionary();
  }

  async ngOnInit() {
    // this.usageService.items = this.transactions.concat(this.transactions).concat(this.transactions);
    this.usageService.items = this.transactions;
    this.usageService.itemsCount = this.usageService.items.length;
    await this.utils.setTimeout();
    this.initScrollHelper();
  }

  initScrollHelper() {
    // if (!this.scroll?.nativeElement)
    //   this.scroll = new ElementRef(document.getElementById('creation-list'));
    if (this.scrollHelper) this.scrollHelper.destroy();
    if (this.scroll.nativeElement) {
      this.scrollHelper = new ScrollHelper(this.scroll.nativeElement);
      this.scrollHelper.maxPercentage = 0.6;
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
    }
  }

  fetchMore() {
    if (typeof this.usageService.itemsCount === 'number') {
      if (
        this.usageService.itemsCount <= this.usageService.items.length
      )
        return;
    } else return;
    this.usageService.scrollOffset = this.usageService.items.length;
    this.usageService.searchByQuery();
  }

  getAllocationName(productId: string) {
    if (this.productsDictionary[productId]) {
      return this.productsDictionary[productId].name;
    }
    this.setProductsDictionary(productId);
    return '';
  }

  async setProductsDictionary(productId: string) {
    this.productsDictionary[productId] = await this.paddleService.getProductDetails(productId);
  }
}
