<div class="wrap pos-rel">
  <mat-dialog-content>
    <h2 mat-dialog-title class="capitalize-fl">
      3D Marketplace
      <button
        mat-dialog-close
        mat-raised-button
        aria-label="mobile menu"
        class="btn btn-c no-border btn-img pos-abs"
      >
        <img [src]="'/assets/images/close.svg'" alt="menu" class="va-sub" />
      </button>
    </h2>
    <div class="disp-flex row mobile-col">
      <div class="flex-def">
        <div class="info">
          <p class="ttl txt-xxs bold capitalize-fl">name</p>
          <div class="desc">{{ item.name }}</div>
        </div>
        <div class="info">
          <p class="ttl txt-xxs bold capitalize-fl">geometry</p>
          <div class="desc">
            <div>
              Poly type: Mid poly | {{ item.poly_count | number }} Triangles
            </div>
            <div>
              file type: glTF@if (item.products_resources_source_files?.length
              && softwares) {, @for (item of
              item.products_resources_source_files; track $index; let last =
              $last) {
              {{ softwares[item.software_id].software_name }}
              @if (!last) {,} } }
            </div>
            <div>file size: {{ GLBSize | number : "1.0-1" }}mb</div>
          </div>
        </div>
        <div class="info">
          <p class="ttl txt-xxs bold capitalize-fl">price</p>
          <div class="desc ttl-lg">
            <img src="/assets/images/coin.svg" alt="coin" class="va-sub" />
            @if (item.credits) {
            {{ item.credits | number }}
            } @else {
            <span class="free">Free</span>
            }
          </div>
          @if (item.credits) {
          <div class="available capitalize-fl">
            available credits: {{ auth.credits | number }}
          </div>
          }
        </div>
      </div>
      <div class="flex-def pos-rel">
        <div class="viewer-wrapper" #viewerWrapper></div>
        <app-viewer-controls
          [viewOnly]="true"
          [viewerService]="viewerService"
          class="pos-abs horizontal-align"
        ></app-viewer-controls>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      type="button"
      mat-raised-button
      mat-dialog-close
      class="btn large btn-d"
    >
      close
    </button>
    <button type="button" mat-raised-button class="btn large btn-a" (click)="buy()">
      @if (item.credits) {
        <img src="/assets/images/coin.svg" alt="coin" class="va-sub" />
        {{ item.credits | number }} <span class="va-top">|</span> Buy 3D model
        } @else {
          get 3D model
        }
      
    </button>
  </mat-dialog-actions>
</div>
