import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
  standalone: true
})
export class MomentPipe implements PipeTransform {
  
  transform(value: any, method: string, f?: string, arg1?: any): any {
    let date = Date.parse(value) as any;
    if (isNaN(date))
      date = new Date(value * 1);
    const momentAny = moment.default as any;
    if (!isNaN(date)) {
      switch (method) {
        case 'format': {
          return momentAny(date).format(f);
        }
        case 'fromNow': {
          let format = 'YYYY-MM-DD HH:mm:ss.SSS';
          // let format = 'YYYY-MM-DDTHH:mm:ss.SSZ';
          if (f)
            format = f;
          var date1 = momentAny(date).format(format);
          var stillUtc = momentAny.utc(date1).toDate();
          var local = momentAny(stillUtc).local().format(format);
          return momentAny(local).fromNow(arg1);
        }
        case 'fromNow2': {
          return momentAny(date).fromNow();
        }
        case 'toNow': {
          return momentAny(date).toNow(arg1);
        }
      }
      return momentAny(date).format("MMM Do YY"); //new Date(date).toUTCString();
    }
    return value;
  }

}




