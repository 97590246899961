<form class="wrap" [formGroup]="creationsService.filter">
  <div class="filter">
    @for (item of filters; track $index) {
    <button
      type="button"
      mat-raised-button
      class="btn btn-f small"
      (click)="toggle(item.key)"
      [ngClass]="{ active: dictionary[item.key] }"
    >
      {{ item.value }}
    </button>
    } @if (creationsService.filter) {
    <button
      type="button"
      mat-raised-button
      class="btn btn-f small"
      (click)="toggleArchived()"
      [ngClass]="{
        active: creationsService.filter.controls['is_archived'].value
      }"
    >
      archived
    </button>
    }
  </div>
</form>
