import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IPricingCardDetails } from '../pricing';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { PaddleService } from '../../payments/paddle.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../shared/utils.service';
import { ScreenNotificationType } from '../../shared/enums';

@Component({
  selector: 'app-pricing-card',
  standalone: true,
  imports: [MatButtonModule, CommonModule],
  templateUrl: './pricing-card.component.html',
  styleUrl: './pricing-card.component.scss',
})
export class PricingCardComponent implements OnChanges {
  @Input() details: IPricingCardDetails;
  public cliced: boolean;
  public hasOrgPrice: boolean;
  constructor(
    private paddleService: PaddleService,
    private auth: AuthService,
    private router: Router,
    private utils: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setHasOrgPrice();
  }

  setHasOrgPrice() {
    if (this.details.orgPrice && this.details.orgPrice !== this.details.price)
      this.hasOrgPrice = true;
    else this.hasOrgPrice = false;
  }

  async pay() {
    if (this.details.priceId) {
      this.cliced = true;
      let success = false;
      if (this.auth.subscription) {
        try {
          await this.paddleService.changeSubscription(this.details.priceId, 1);
          success = true;
          this.utils.notifyUser({
            type: ScreenNotificationType.Success,
            text: 'Subscription successfully updated',
          });
        } catch (e: any) {
          this.utils.notifyUser({
            type: ScreenNotificationType.Error,
            text: `${e?.error?.error ? e.error.error : 'Something went wrong'}`,
          });
        }
      } else {
        try {
          await this.paddleService.checkout(this.details.priceId, 1);
          success = true;
          this.utils.notifyUser({
            type: ScreenNotificationType.Success,
            text: 'Subscription successfully acquired',
          });
        } catch (e: any) {
          this.utils.notifyUser({
            type: ScreenNotificationType.Error,
            text: `${e?.error?.error ? e.error.error : 'Something went wrong'}`,
          });
        }
      }
      if (success) this.router.navigate(['/generate']);
    } else {
      if (this.auth.subscription) {
        try {
          await this.paddleService.cancelSubscription();
          this.router.navigate(['/generate']);
          this.utils.notifyUser({
            type: ScreenNotificationType.Success,
            text: 'Subscription successfully canceled',
          });
        } catch (e: any) {
          this.utils.notifyUser({
            type: ScreenNotificationType.Error,
            text: `${e?.error?.error ? e.error.error : 'Something went wrong'}`,
          });
        }
      }
      else {
        if (await this.auth.isloggedIn()) this.router.navigate(['/generate']);
        else this.router.navigate(['/login']);
      }
    }
  }
}
