<div class="wrap disp-flex row mobile-col-reverse">
  <div class="left disp-flex col view-min-height">
    <div>
      <div class="component-wrap">
        <div class="buttons disp-flex">
          <div class="button-wrap">
            <button
              (click)="state = MEDIA_TYPE.MODEL"
              [ngClass]="{ active: state === MEDIA_TYPE.MODEL }"
              type="button"
              mat-raised-button
              class="btn btn-g large-desktop small-text"
            >
              3D model
            </button>
          </div>
          <div class="button-wrap">
            <button
              (click)="state = MEDIA_TYPE.IMAGE"
              [ngClass]="{ active: state === MEDIA_TYPE.IMAGE }"
              type="button"
              mat-raised-button
              class="btn btn-g large-desktop small-text"
            >
              Images
            </button>
          </div>
        </div>
      </div>
    </div>
    @if (state === MEDIA_TYPE.MODEL) {
    <app-creations-filter class="space-bottom-xl elem"></app-creations-filter>
    <div class="hidden-desktop txt-xxs bold desc-text capitalize-fl">
      select 3D model
    </div>
    <app-creations
      class="elem no-space-top no-space-right-desktop"
      (onSelect)="onSelect($event)"
      [type]="CREATIONS_LIST_TYPE.DESKTOP_INFO_HORIZON_THUMBS_MOBILE"
      [counter]="generateService.counter"
      [actions]="true"
    ></app-creations>
    } @else {
    <div class="capitalize-fl txt-sm space-bottom-lg elem hidden-mobile">
      select and generate 3D models from previously generated images
    </div>
    <app-generated-images
      class="elem"
      (onSelect)="onImageSelect($event)"
      [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"
    ></app-generated-images>
    }
  </div>
  <div
    class="flex-def disp-flex creation-wrap"
    [ngClass]="{
      'has-content':
        (state === MEDIA_TYPE.MODEL && generateService.creation) ||
        generateService.reconstructedImage
    }"
  >
    <button
      type="button"
      mat-raised-button
      mat-dialog-close
      class="btn large btn-g close"
      (click)="close()"
    >
      close
    </button>
    <div
      class="view-min-height flex-1 right"
      [ngClass]="{
        empty:
          (state === MEDIA_TYPE.MODEL && !generateService.creation) ||
          (state !== MEDIA_TYPE.MODEL && !generateService.reconstructedImage)
      }"
    >
      @if (state === MEDIA_TYPE.MODEL) {
      <div
        class="desc col hidden-desktop align-items-center justify-content-center w-100"
      >
        <img src="/assets/images/cube.svg" alt="cube" />
        <div class="text-center">Select 3D model</div>
      </div>
      } @else {
      <div
        class="desc col hidden-desktop align-items-center justify-content-center w-100"
      >
        <img src="/assets/images/image.svg" alt="cube" class="sharpen1" />
        <div class="text-center">Select image & generate 3D model</div>
      </div>
      } @if (state === MEDIA_TYPE.MODEL) { @if(generateService.creation) {
      <app-view-creation
        class="disp-block h-100"
        [creation]="generateService.creation"
      ></app-view-creation>
      } } @else if(generateService.reconstructedImage) {
      <app-view-generated-image
        [item]="generateService.reconstructedImage"
      ></app-view-generated-image>
      }
    </div>
  </div>
  <div class="similar-wrap" [ngClass]="{ open: showSimilar }">
    @if (generateService.hasPreview()) {
    <button
      type="button"
      mat-raised-button
      class="btn btn-a inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()"
    >
      <img src="/assets/images/marketplace-1.svg" alt="marketplace" />
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn btn-c close-inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()"
    >
      <img src="/assets/images/close.svg" alt="close" class="va-bottom" />
    </button>
    <app-similar
      class="disp-block"
      [items]="generateService.similarItems"
      (onSelect)="setSimilar($event)"
      (onClose)="showSimilar = false"
    ></app-similar>
    }
  </div>
</div>
