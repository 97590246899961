<app-balls></app-balls>
<div class="wrap component-wrap">
  <!-- <div class="vertical-align"> -->
  <div>
    <div class="top-section">
      <app-hexa-logo></app-hexa-logo>
      <h1>
        Unlock Your Creativity with
        <span class="ai-powered">AI-Powered 3D</span>
      </h1>
      <div class="desc">
        Unleash the potential of generative AI with {{ platformName }}, the
        revolutionary platform that transforms images and text into stunning 3D
        models. From imagination to reality, {{ platformName }} empowers you to
        bring your ideas to life like never before.
      </div>
      <div>
        <button (click)="start()" mat-raised-button class="btn btn-a big cta">
          get started
        </button>
      </div>
    </div>
    <h2 class="text-center ttl-xl">Transform Vision into Virtual Reality</h2>
    <div class="sections-wrap disp-flex row">
      <section>
        <p>Transform Vision into Virtual Reality</p>
        <div>
          <p>
            <b>Effortless 3D Creation:</b> Turn images or text prompts into
            stunning 3D models with ease and speed.
          </p>
          <p>
            <b>State-of-the-Art AI:</b> Harness the power of cutting-edge
            artificial intelligence to bring your ideas to life.
          </p>
        </div>
      </section>
      <section>
        <p>Instant Sharing & Downloads</p>
        <div>
          <p>
            <b>Interactive Online Viewer:</b> Share your creations with the
            world through an immersive web viewer.
          </p>
          <p>
            <b>Downloadable Assets:</b> Get your hands on a glTF file of your
            model for further use and application.
          </p>
        </div>
      </section>
      <section>
        <p>Discover Professional Models</p>
        <div>
          <p>
            <b>Curated Marketplace:</b> Browse through a selection of
            professionally crafted 3D models that complement your own creation.
          </p>
          <p>
            <b>Exclusive Purchases:</b> Acquire intricate models for your
            project, with Substance Painter source files available for in-depth
            customization.
          </p>
        </div>
      </section>
      <section>
        <p>Token Rewards System</p>
        <div>
          <p>
            <b>Free Tokens on Sign-Up:</b> Start your journey with 200 free
            tokens, enough to generate five models at no cost.
          </p>
          <p>
            <b>Monthly Token Refill:</b> Stay active and receive up to 200
            additional tokens each month to fuel your creative endeavors.
          </p>
        </div>
      </section>
      <section>
        <p>Incentive for Engagement</p>
        <div>
          <p>
            <b>Continual Supply for Creatives:</b> No need to worry about
            running out of tokens — active users receive refills to keep the
            ideas flowing.
          </p>
          <p>
            <b>Balance Cap for Fairness:</b> With a cap of 200 tokens, we ensure
            that all users get a chance to receive monthly token bonuses.
          </p>
        </div>
      </section>
      <section>
        <p>Join {{ platformName }} Today</p>
        <div>
          <p>
            <b>Launch into 3D:</b> Leap into the future of digital creativity
            and 3D model generation. Sign up now and transform your imagination
            into virtual reality!
          </p>
          <p>
            <b>Community of Innovators:</b> Become part of a thriving community
            of artists, designers, and creatives who push the boundaries of 3D
            modeling.
          </p>
        </div>
      </section>
      <section>
        <p>Your Imagination, Our Technology</p>
        <div>
          Unbox your creativity with {{ platformName }}. Sign up now and start
          shaping your world.
        </div>
      </section>
    </div>
  </div>
  <br /><br />
</div>
