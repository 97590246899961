<div class="wrap disp-flex" [ngClass]="{'sm': seperateMobile}">
  <span class="disable-color">
    Cost:
    <span class="default-color bold"
      ><img class="btn-icon" src="/assets/images/coin.svg" alt="coin" />
      {{cost}}</span
    >
  </span>
  <span class="disable-color align-end-flex flex-1 text-end est">
    Est. time: <span class="default-color bold">{{estDisplay}} {{time}}</span>
  </span>
</div>