<div class="wrap">
    <h3>
        Announcement
    </h3>
    <div class="text-wrap">
        <app-text-editor [click-img]="true" [disable]="true" [init-value]="data.notes"></app-text-editor>
        <br>
    </div>
    <button mat-raised-button color="primary" (click)="onYesClick()">
        O.K.
    </button>
    <button mat-raised-button color="accent" (click)="onNoClick()" class="float-op-dir">
        ignore
    </button>
</div>