import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-terms-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './terms-view.component.html',
  styleUrl: './terms-view.component.scss'
})
export class TermsViewComponent {
  public isloggedIn: boolean
  @Input() partial: boolean
  constructor(
    private auth: AuthService
  ) {
    this.init();
  }

  async init() {
    this.isloggedIn = await this.auth.isloggedIn();
  }
}
