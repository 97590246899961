import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextEditorComponent } from '../text-editor/text-editor.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-custom-notification',
  standalone: true,
  imports: [TextEditorComponent, MatButtonModule],
  templateUrl: './custom-notification.component.html',
  styleUrl: './custom-notification.component.scss'
})
export class CustomNotificationComponent {
  constructor(
    public dialogRef: MatDialogRef<CustomNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close(this.data.notificationId);
  }
}
