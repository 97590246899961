import { IFilterOptions, RECONSTRUCTION_ACTION } from '../generate/generate';
import { IHasDates } from '../shared/utils';

export enum PricingPeriod {
  MONTHLY = 1,
  YEARLY = 2,
}

export enum PricingPlan {
  NONE = 0,
  MONTHLY_SM = 1,
  MONTHLY_MD = 2,
  MONTHLY_LG = 3,
  YEARLY_SM = 4,
  YEARLY_MD = 5,
  YEARLY_LG = 6,
}

export interface IPricingCardDetails {
  //   type: PricingPlan;
  name: string;
  price: number;
  desc: string;
  attributes: Array<string>;
  orgPrice: number;
  isPopular: boolean;
  priceId: string;
  credits: number;
  isCurrent: boolean;
  billingCycle: IPaymentPackagePriceBillingCycle;
}

export interface IPaymentPackage extends IHasDates {
  id: string;
  name: string;
  tax_category: string; // "standard",
  type: string; // "standard",
  description: string;
  image_url: string;
  custom_data: any;
  status: string; // "active",
  import_meta: any;
  prices: Array<IPaymentPackagePrice>;
}

export interface IPaymentPackagePrice extends IHasDates {
  id: string; // "pri_01hsxbatqgmrtcp3bcg8fyg980",
  product_id: string; // "pro_01hsxba6tfgkhtr69kjrfxsw9n",
  type: string; // "standard",
  description: string; // "5000 Credits - OTP",
  name: string; // "5000 Credits",
  billing_cycle: IPaymentPackagePriceBillingCycle; // null,
  trial_period: any; // null,
  tax_mode: string; // "account_setting",
  unit_price: IPaymentPackagePriceUnitPrice;
  unit_price_overrides: Array<IPaymentPackagePriceUnitPriceOverride>;
  custom_data: { [id: string]: string }; // IPaymentPackagePriceCustomData;
  status: string; // "active",
  quantity: IPaymentPackagePriceQuantity;
  import_meta: any;
}

export interface IPaymentPackagePriceQuantity {
  minimum: number;
  maximum: number;
}

// export interface IPaymentPackagePriceCustomData {
//     // Credits: number;
// }

export interface IPaymentPackagePriceUnitPriceOverride {}

export interface IPaymentPackagePriceUnitPrice {
  amount: string; // "9000";
  currency_code: string; // "USD"
}

export interface IPaymentPackagePriceBillingCycle {
  interval: 'year' | 'month';
  frequency: number; // 1
}

export interface IReconstructionTransaction {
  id: number;
  created_at: Date;
  updated_at: Date;
  user_id: number;
  transaction_id: number;
  product_id: string;
  price_id: string;
  amount: number;
  currency: string;
  credits: number;
  status: string;
  job_id: number;
  action_id: RECONSTRUCTION_ACTION;
  reconstruction_jobs: Array<IReconstructionTransactionJob>;
}

export interface IReconstructionTransactionJob {
  action_id: number;
}

export interface ITransactionFilterOptions extends IFilterOptions {}

export interface ITopupCardDetails {
  price: number;
  desc: string;
}

export interface IPaddleEventCallback {
  name: string;
  data: IPaddleEventCallbackData;
}

export interface IPaddleEventCallbackData {
  currency_code: string; // "USD"
  custom_data: any; // null
  customer: IPaddleEventCallbackDataCustomer;
  id: string; // "che_01j2bhjqmbyk9yb1eepk7s5s28"
  items: Array<IPaymentPackagePrice>;
  payment: IPaddleEventCallbackDataPayment;
  recurring_totals: IPaddleEventCallbackDataRecurring;
  settings: IPaddleEventCallbackDataSettings;
  status: string;
  totals: IPaddleEventCallbackDataTotals;
  transaction_id: string; // "txn_01j2bhjqghaav4evc29r1emab5"
}

export interface IPaddleEventCallbackDataTotals {
  balance: number; // 0
  credit: number; // 0
  discount: number; // 0
  subtotal: number; // 15
  tax: number; // 0
  total: number; // 15
}

export interface IPaddleEventCallbackDataSettings {
  display_mode: string; // "wide-overlay"
  theme: string; // "dark"
}

export interface IPaddleEventCallbackDataRecurring {
  balance: number; // 15
  credit: number; // 0
  discount: number; // 0
  subtotal: number; // 15
  tax: number; // 0
  total: number; // 15
}
export interface IPaddleEventCallbackDataPayment {
  method_details: IPaddleEventCallbackDataPaymentMethod;
}

export interface IPaddleEventCallbackDataPaymentMethod {
  card: IPaddleEventCallbackDataPaymentMethodCard;
  type: string; // "card"
}

export interface IPaddleEventCallbackDataPaymentMethodCard {
  expiry_month: number; // 10
  expiry_year: number; // 2026
  last4: string; // "5556"
  type: string; // "visa"
}

export interface IPaddleEventCallbackDataCustomer {
  address: IPaddleEventCallbackDataCustomerAddress;
  business: any; // null
  email: string; // ran@hexa3d.io
  id: string; // "ctm_01j2bhjqewq4xx6q7gtwnjsnhk"
}

export interface IPaddleEventCallbackDataCustomerAddress {
  city: string; // ""
  country_code: string; // "IL"
  first_line: string; // ""
  id: string; // "add_01j2bhk4nfj655ytaszcc7egfx"
  postal_code: string; // ""
  region: string; // ""
}
