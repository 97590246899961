import { Injectable } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { PixelsService } from '../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { PixelArtistsEvents } from '../shared/pixels';
import { IScreenNotification, ScreenNotificationType } from '../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  static VAPID_PUBLIC_KEY = 'BN-y-aW3K3DN_tjNO8YA_2o6K6HFkTqAzxGRXwIe1CZanFMaPGXiRuYX5zzUotym3jFiqkokTsAm9IX0PJJDA2M';//'AIzaSyCKSGmYlRRs64ps9xqosA4v7b8A17fH_RY';
  static LAST_TEASER_STORAGE = 'push-teaser-last';
  static WEEK_MS = 604800000;
  private deferredPrompt: any; // BeforeInstallPromptEvent
  // private subscriptionRequested: boolean;
  // private _isPushTeaserDialogOpen: boolean;
  constructor(
    private swUpdate: SwUpdate,
    private broadcaster: BroadcasterService,
    private swPush: SwPush,
    private auth: AuthService,
    private pixels: PixelsService
  ) {
    // this.subscriptionRequested = false;
    if (environment.production) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.broadcaster.broadcast('beforeinstallprompt');
      });

      this.swUpdate.activateUpdate().then(hasNewVersion => {
        if (hasNewVersion)
          this.onNewVersion();
      });
      this.swUpdate.checkForUpdate().then(hasNewVersion => {
        if (hasNewVersion)
          this.onNewVersion();
      });
      // this.swUpdate.available.subscribe(event => {
      //   this.onNewVersion();
      // });
      this.broadcaster.on('userRefreshed').subscribe(this.checkForUpdate.bind(this));
      this.broadcaster.on('onLogin').subscribe(this.onLogin.bind(this));
      // this.broadcaster.on('pushTeaser').subscribe(this.pushTeaser.bind(this));
      // this.broadcaster.on('subscribeToPush').subscribe(this.subscribeToPush.bind(this));
      // if (this.auth.isloggedIn())
      //   this.subscribeToPush();
      if (this.auth.isloggedIn())
        this.onLogin();
    }
    // this.onPushNotification = new Subject<any>();
    this.swPush.notificationClicks.subscribe(
      ({ action, notification }) => {
        if (notification && notification !== null && notification['data']) {
          const url = notification['data'].url;
          window.open(url, '_blank');
        }
      });
  }

  // get isPushTeaserDialogOpen() {
  //   return this._isPushTeaserDialogOpen;
  // }

  private onLogin() {
    // let ps = this.storage.get('pushSubscription');
    // if (ps)
    //   this.sendPushSubscription(ps);
    // if (this.isSubscribed()) {
    //   this.subscribeToPush();
    // }
  }

  // private sendPushSubscription(ps: any) {
  //   const client_id = this.utils.getClientId()
  //   let obj = {
  //     endpoint: ps.endpoint,
  //     auth: ps.keys.auth,
  //     public_key: ps.keys.p256dh,
  //     client_id: client_id
  //   };
  //   if (obj.endpoint && obj.auth && obj.public_key) {
  //     this.auth.user.artists_users_push_details.push({
  //       artist_user_id: this.auth.user.id,
  //       endpoint: ps.endpoint,
  //       auth: ps.keys.auth,
  //       public_key: ps.keys.p256dh,
  //       client_id: client_id
  //     });

  //     this.rest.pushDetails('post', obj).subscribe(
  //       () => {
  //         this.auth.refreshUserDate();
  //       }
  //     )
  //   }
  //   else {
  //     console.warn('push request subscription - missing values:\n' + JSON.stringify(obj));
  //   }
  // }

  // pushTeaser(force: boolean) {
  //   if (!window['Notification']) return;
  //   if (!environment.production || this.subscriptionRequested || this.isSubscribed()) return;
  //   let date = this.storage.get(SwService.LAST_TEASER_STORAGE);
  //   if (date)
  //     date = date * 1;
  //   else
  //     date = 1;
  //   if (force || !isNaN(date)) {
  //     if (force || this.utils.isAboveTS(new Date().getTime(), date, SwService.WEEK_MS)) {
  //       const dialogRef = this.matDialog.open(PushTeaser, {
  //         data: {
  //           denied: this.isSubscribedDenied(),
  //           subscribed: this.isSubscribed()
  //         }
  //       });
  //       this._isPushTeaserDialogOpen = true;
  //       dialogRef.afterClosed().subscribe(res => {
  //         this._isPushTeaserDialogOpen = false;
  //         if (res)
  //           this.subscribeToPush.apply(this);
  //         this.broadcaster.broadcast('pushTeaserDialogClosed', res);
  //       });
  //       this.storage.set(SwService.LAST_TEASER_STORAGE, new Date().getTime());
  //     }
  //   }
  // }

  public isSubscribed(): boolean {
    // Notification.permission = 'default', 'granted' or 'denied'
    return window['Notification'] && window['Notification']['permission'] == 'granted';
  }

  public isSubscribedDenied(): boolean {
    return window['Notification'] && window['Notification']['permission'] == 'denied';
  }

  // public subscribeToPush() {
  //   if (!window['Notification']) return;
  //   if (!environment.production || this.subscriptionRequested) return;
  //   this.subscriptionRequested = true;
  //   this.swPush.requestSubscription(
  //     {
  //       serverPublicKey: SwService.VAPID_PUBLIC_KEY
  //     }
  //   ).then(
  //     (pushSubscription: PushSubscription) => {
  //       let ps = null as any;
  //       try {
  //         ps = pushSubscription.toJSON();
  //       }
  //       catch (e) {
  //         ps = pushSubscription;
  //       }

  //       if (this.auth.isloggedIn()) {
  //         this.sendPushSubscription(ps);
  //         this.pixels.sendPixel({ event: 'pushSubscription' });
  //         // let data: Notification = {
  //         //   text: 'subscribed successfully',
  //         //   type: NotificationType.Success,
  //         //   action: 'O.K.',
  //         // };
  //         // this.broadcaster.broadcast('notifyUser', data);
  //       }
  //       // else {
  //       this.storage.set('pushSubscription', ps);
  //       // }
  //       // }

  //       // this.onPushNotification.next(pushSubscription);
  //     }
  //   )
  // }

  private onNewVersion() {
    let data: IScreenNotification = {
      text: 'A newer version is available',
      type: ScreenNotificationType.Info,
      action: 'REFRESH',
      callback: this.refresh,
      scope: this,
      autoDismiss: false
    };
    this.broadcaster.broadcast('notifyUser', data);
    this.broadcaster.broadcast('onNewVersion', data);
  }

  public checkForUpdate() {
    this.swUpdate.checkForUpdate();
  }

  public refresh() {
    window.location.reload();
  }

  public isAddToHomescreenEnable(): boolean {
    return !!this.deferredPrompt;
  }

  public addToHomescreen() {
    if (this.deferredPrompt !== undefined) {
      // The user has had a positive interaction with our app and Chrome
      // has tried to prompt previously, so let's show the prompt.
      this.deferredPrompt.prompt();

      // Follow what the user has done with the prompt.
      this.deferredPrompt.userChoice.then((choiceResult: any) => {

        // console.log(choiceResult.outcome);
        let options = {
          event: 'addToHomescreen'
        } as PixelArtistsEvents;
        if (choiceResult.outcome == 'dismissed') {
          options.event = 'addToHomescreenDismissed';
          // console.log('User cancelled home screen install');
        }
        // else {
        //   console.log('User added to home screen');
        // }
        this.pixels.sendPixel(options);

        // We no longer need the prompt.  Clear it up.
        this.deferredPrompt = null;
      });
    }
  }
}
