import { Injectable, Injector } from '@angular/core';
import { InfiniteScroll } from '../shared/infinite-scroll';
import { ITransactionFilterOptions } from '../pricing/pricing';
import { EnumsService } from '../shared/enums.service';
import { UtilsService } from '../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class UsageService extends InfiniteScroll {
  static DEFAULT_FILTER = {
    limit: 20,
    offset: 0,
    // is_desc: true,
    // order_by: 'session_id',
  } as ITransactionFilterOptions;
  constructor(
    private enums: EnumsService,
    private utils: UtilsService,
    private injector: Injector
  ) {
    super(
      {
        chunkSize: UsageService.DEFAULT_FILTER.limit,
        graphFunction: 'allReconstructionTransactions',
        defaults: {
          is_desc: UsageService.DEFAULT_FILTER.is_desc,
          order_by: UsageService.DEFAULT_FILTER.order_by
        } as ITransactionFilterOptions,
      },
      injector
    );
  }
}
