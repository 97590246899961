<div class="wrap text-center align-items-center justify-content-center">
  <div class="inner vertical-align-desktiop-only">
    <div class="text-start ttl-xxs">
      Select the most accurate image to your description
    </div>
    <p class="text-start txt-xxs">
      Or chooes from our high quality ready-to-use similar items
    </p>
    <div class="pos-rel progress-bar-wrap">
      @if (loading) {
      <mat-progress-bar class="pos-abs" mode="indeterminate">
      </mat-progress-bar>
      }
    </div>
    <div>
      <div class="images align-items-center">
        @for (img of images; track $index) {
        <app-image-card
          [ngClass]="{
            'border-gradient': generateService.createdImagesIndex === $index
          }"
          class="pointer disp-block"
          (click)="select($index)"
          [src]="img"
        ></app-image-card>
        }
      </div>
      <div class="disp-flex actions space-top">
        <button
          type="button"
          mat-raised-button
          class="btn btn-c large"
          (click)="retry()"
        >
          <img class="btn-icon" src="/assets/images/retry.svg" alt="retry" />
          Retry
        </button>
        <button
          type="button"
          mat-raised-button
          class="btn btn-a align-end-flex flex-1 large"
          (click)="generate(images[generateService.createdImagesIndex])"
        >
          <img class="btn-icon" src="/assets/images/cube.svg" alt="cube" />
          Generate 3D model
        </button>
      </div>
      <app-cost-est [cost]="reconstructionCost" [est]="20" class="disp-block space-top"></app-cost-est>
    </div>
  </div>
</div>
