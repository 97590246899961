<div class="wrap disp-flex mobile-col-reverse">
  <div class="prompt-wrap view-min-height">
    <div class="disp-flex prompt-inner">
      <app-prompt></app-prompt>
      <div class="divider-wrap disp-flex hidden-mobile">
        <mat-divider class="w-100"></mat-divider>
      </div>
      <div class="component-wrap disp-flex align-items-center hidden-mobile">
        <h3 class="txt-sm bold capitalize no-marg flex-1">my creations</h3>
        <a [routerLink]="['/creations']" class="txt-xxs link bold">
          view all
        </a>
      </div>
      <app-creations
        class="component-wrap"
        [current]="generateService.creation"
        [counter]="generateService.counter"
        (onSelect)="onSelect($event)"
        [actions]="true"
      ></app-creations>
      <!-- @if (generateService.latestUpdated) {
      <div class="hidden-desktop">
        <h3 class="txt-sm bold capitalize no-marg flex-1 hidden-mobile">
          current job
        </h3>
        <app-creation-card
          (onSelect)="onSelect($event)"
          [item]="generateService.latestUpdated"
          [counter]="generateService.counter"
        ></app-creation-card>
      </div>
      } -->
    </div>
  </div>
  <div
    class="creation-wrap view-min-height"
    [ngClass]="{
      empty: !generateService.creation && !generateService.createdImages
    }"
  >
    @if(generateService.creation) {
    <app-view-creation
      class="disp-block h-100"
      [creation]="generateService.creation"
      [counter]="generateService.counter"
      (onPreview)="setPreview($event)"
    ></app-view-creation>
    } @else if (generateService.createdImages) {
    <div class="back-header hidden-desktop disp-flex">
      <a
        href="javascript:void(0)"
        (click)="generateService.createdImages = null"
      >
        <img src="/assets/images/back.svg" alt="back" />
      </a>
      <div class="ttl-xs flex-def text-center">Preview images</div>
    </div>
    <app-generate-results
      class="disp-block view-min-height"
      [images]="generateService.createdImages"
    ></app-generate-results>
    }
  </div>
  <div class="similar-wrap" [ngClass]="{ open: showSimilar }">
    @if (generateService.hasPreview()) {
    <button
      type="button"
      mat-raised-button
      class="btn btn-a inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()"
    >
      <img src="/assets/images/marketplace-1.svg" alt="marketplace" />
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn btn-c close-inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()"
    >
      <img src="/assets/images/close.svg" alt="close" class="va-bottom" />
    </button>
    <app-similar
      class="disp-block"
      [items]="generateService.similarItems"
      (onSelect)="setSimilar($event)"
      (onClose)="showSimilar = false"
    ></app-similar>
    }
  </div>
</div>
