import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-cost-est',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cost-est.component.html',
  styleUrl: './cost-est.component.scss'
})
export class CostEstComponent implements OnChanges {
  public time = 'Sec';
  public estDisplay: number;
  @Input() cost: number;
  @Input() est: number;
  @Input('seperate-mobile') seperateMobile: boolean;
  ngOnChanges() {
    this.calcDisplay();
  }

  calcDisplay() {
    this.estDisplay = this.est;
    this.time = 'Sec';
    if (this.est >= 60) {
      this.estDisplay = this.est / 60;
      this.time = 'Min';
    }
  }
}
