import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GeneratedImagesService } from '../generated-images.service';
import { IAllReconstructionImages, IMAGE_LIST_TYPE, IReconstructImage, IReconstructImageUI, IReconstructJobImageOptions } from '../generate';
import { ScrollHelper } from '../../shared/scroll-helper';
import { UtilsService } from '../../shared/utils.service';
import { GraphqlService } from '../../communication/graphql.service';
import { ApolloQueryResult } from '@apollo/client';
import { GeneratedImageCardComponent } from '../generated-image-card/generated-image-card.component';
import { imageCardDelay, mobileThreshold } from '../../shared/constants';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-generated-images',
  standalone: true,
  imports: [GeneratedImageCardComponent, CommonModule],
  templateUrl: './generated-images.component.html',
  styleUrl: './generated-images.component.scss'
})
export class GeneratedImagesComponent {
  @Input() current: IReconstructImage;
  @Input('type') type: IMAGE_LIST_TYPE;
  public IMAGE_LIST_TYPE = IMAGE_LIST_TYPE;
  @ViewChild('scroll') public scroll: ElementRef;
  @Output() onSelect: EventEmitter<IReconstructImage>;
  private scrollHelper: ScrollHelper;
  constructor(
    public giService: GeneratedImagesService,
    private utils: UtilsService,
    private gql: GraphqlService
  ) {
    this.onSelect = new EventEmitter<IReconstructImage>();
  }

  async ngOnInit() {
    const items = await this.utils.observableToPromise(
      this.gql.allReconstructionImages({
        limit: GeneratedImagesService.DEFAULT_FILTER.limit,
        offset: GeneratedImagesService.DEFAULT_FILTER.offset
      } as IReconstructJobImageOptions)
    ) as ApolloQueryResult<IAllReconstructionImages>;
    this.giService.items = this.utils.deepCopyByValue(items.data.allReconstructionImages.rows);
    this.giService.itemsCount = items.data.allReconstructionImages.count;
    for (let i = 0; i < this.giService.items.length; i++) {
      (this.giService.items[i] as IReconstructImageUI)._delayEnter = (i * imageCardDelay);
    }
    await this.utils.setTimeout();
    this.initScrollHelper();
  }

  initScrollHelper() {
    if (!this.scroll?.nativeElement)
      this.scroll = new ElementRef(document.getElementById('creation-images-list'));
    if (this.scrollHelper) this.scrollHelper.destroy();
    if (this.scroll.nativeElement) {
      this.scrollHelper = new ScrollHelper(this.scroll.nativeElement);
      this.scrollHelper.maxPercentage = 0.6;
      // this.scrollHelper.hirizontalThreshold = mobileThreshold;
      this.scrollHelper.onScrollThreshold.subscribe(this.fetchMore.bind(this));
    }
  }

  fetchMore() {
    if (typeof this.giService.itemsCount === 'number') {
      if (
        this.giService.itemsCount <= this.giService.items.length
      )
        return;
    } else return;
    this.giService.scrollOffset = this.giService.items.length;
    this.giService.searchByQuery();
  }

  setImage(item: IReconstructImage) {
    this.current = item;
    this.onSelect.next(item);
  }
}
