import { Component, Input } from '@angular/core';
import { ISimilarItem } from '../generate';
import { SrcSuffixPipe } from '../../shared/src-suffix.pipe';

@Component({
  selector: 'app-similar-item',
  standalone: true,
  imports: [SrcSuffixPipe],
  templateUrl: './similar-item.component.html',
  styleUrl: './similar-item.component.scss'
})
export class SimilarItemComponent {
  @Input() item: ISimilarItem;
}
