import { inject } from "@angular/core";
import { HttpInterceptorFn } from "@angular/common/http";
import { User } from "../auth/user";
import { StorageService } from "ng-storage-service";


export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
    const storage = inject(StorageService);
    let authToken = '';
    let u = storage.get('user') as User;
    if (u && u?.token) {
      authToken = storage.get('token');
      if (!authToken)
        authToken = u.token;
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', authToken)
    });
    return next(authReq);
};