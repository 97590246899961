<div class="wrap">
  <div class="disp-flex row head">
    <div class="flex-def th date bold">Date & Time</div>
    <div class="flex-def th hidden-mobile bold">Type</div>
    <div class="lim-w th amount bold">Amount</div>
    <div class="lim-w th balance bold">Balance</div>
  </div>
  <div class="scroll" #scroll>
    @for (item of usageService.items; track $index) {
    <div class="disp-flex row tr">
      <div class="flex-def td content-va date">
        {{ item.created_at | moment : "format" : "MMM Do YY, hh:mm" }}
      </div>
      <div class="flex-def td capitalize-fl content-va hidden-mobile">
        @if (!item.action_id) {
        {{ getAllocationName(item.product_id) }}
        } @else {
        {{ actionsDictionary[item.action_id] }}
        }
      </div>
      <div class="td content-va lim-w">
        @if (item.action_id) {-}{{ item.amount | number }}
      </div>
      <div class="td content-va lim-w">
        {{ item.credits | number }}
      </div>
    </div>
    }
  </div>
</div>
