<div class="wrap disp-flex col">
  <div class="disp-flex row section first">
    <div>
      @if (user.avatar_url) {
      <img [src]="user.avatar_url" alt="avatar" class="avatar" />
      } @else {
      <div class="account uppercase">
        {{ firstLetter }}
      </div>
      }
    </div>
    <div class="disp-flex col personal-wrap">
      <span>
        <div class="ellipsis txt-md bold">
          {{ user.firstname }} {{ user.lastname }}
        </div>
      </span>
      <span>
        <div class="ellipsis txt-md email">
          {{ user.email }}
        </div>
      </span>
    </div>
  </div>
  <div class="disp-flex col section">
    <div class="disp-flex row space-bottom-md">
      <span class="flex-def capitalize-fl text-start txt-md">subscription</span>
      <span class="flex-def capitalize-fl text-end">
        <span class="sub-label uppercase">
          @if (profile?.subDetails) {
          {{ profile.subDetails.name }}
          } @else { free }
        </span>
      </span>
    </div>
    <div class="disp-flex row">
      <span class="flex-def capitalize-fl text-start txt-sm">
        available credits
      </span>
      <span class="capitalize-fl text-end txt-sm bold">
        @if (profile) {
        {{ profile.credits | number }}/{{
        profile.subDetails ? (profile.subDetails.credits | number) : defaultMonthlyCredits
        }}
        }
      </span>
    </div>
    <div class="disp-flex">
      <app-progress-bar class="flex-def" [percentage]="50"></app-progress-bar>
    </div>
  </div>
  <div class="disp-flex row section no-lp btn-wrap">
    <button type="button" mat-raised-button class="btn btn-d large" (click)="showFull()">
      <img src="/assets/images/user.svg" alt="user" class="va-middle" />
      My profile
    </button>
  </div>
  <div class="disp-flex row section no-lp btn-wrap">
    <button type="button" mat-raised-button class="btn btn-d error-color large" (click)="logout()">
      <img src="/assets/images/logout.svg" alt="logout" class="va-middle" />
      Log out
    </button>
  </div>
</div>