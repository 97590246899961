<div class="wrap alt-bg pos-rel" [ngClass]="{'open': isSidebarOpen}" #wrap>
    <div class="h2 uppercase pos-rel">
        notifications
        <button type="button" mat-raised-button [matMenuTriggerFor]="menu"
            class="btn btn-d no-marg-img btn-img more-options pos-abs">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button id="notifications-menu" mat-menu-item (click)="notificationsService.markAllAsRead()">Mark all as
                read</button>
        </mat-menu>
    </div>
    <div class="inner float-dir scrollbar sidebar" [ngClass]="{'open': isSidebarOpen}" *ngIf="isSidebarOpen"
        (scroll)="onScroll($event)">

        <div *ngFor="let group of notificationsService.notificationsGroups" class="pos-rel float-dir w-100 row">
            <div class="date-wrap">
                <mat-divider class="gray-50"></mat-divider>
                <p class="text2 alt-bg">
                    {{group.created_at | moment : 'format' : 'dddd, MMMM Do' }}
                </p>
            </div>

            <div class="activity float-dir w-100 pointer" *ngFor="let item of group.items" (click)="action(item)">
                <div class="details float-dir scrollbar">
                    <div class="text2 pos-rel" [ngClass]="{'pointer underline bold': item.onClickArgs}">
                        <div class="desc" [innerHTML]="getTitleByNotification(item)"></div>
                        <span class="status pos-abs" [ngClass]="{'online': !item.read}"></span>
                    </div>
                    <div>
                        <span class="created-at">
                            {{item.created_at | moment : 'format' : 'h:mm a' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>