<header class="component-wrap">
  <div class="logo-wrap">
    <a routerLink="/" class="link text-decoration-none">
      <app-hexa-logo></app-hexa-logo>
    </a>
  </div>
  <div class="links text-center hidden-mobile">
    @if (isloggedIn) {
    <a [routerLink]="['/generate']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('generate')">generate</a>
    <a [routerLink]="['/ai-texture']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('ai-texture')">AI-texturing</a>
    <a [routerLink]="['/creations']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('creations')">my creations</a>
    <a [routerLink]="['/pricing']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('pricing')">buy credits</a>
    } @else {
    <a [routerLink]="['/login']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('login')">login</a>
    <!-- <a
      [routerLink]="['/register']"
      mat-raised-button
      class="btn btn-d"
      routerLinkActive="active"
      >sign up</a
    > -->
    <a [routerLink]="['/pricing']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('pricing')">pricing</a>
    }
  </div>
  <div class="personal text-end pos-rel hidden-mobile" id="users-summary">
    @if (isloggedIn) {
    <a class="balance txt-sm bold pointer td-none" [routerLink]="['/pricing']" (click)="onAction('pricing')">
      <!-- (click)="this.toggleCreditsOptions(!this.showCreditsOptions)"  -->
      <img src="/assets/images/coin.svg" alt="coin" class="va-middle" />
      {{ auth.credits | number }}
    </a>
    <button type="button" mat-raised-button class="btn btn-d no-marg-img btn-img notifications pos-rel"
      (click)="showNotifications = !showNotifications" [ngClass]="{ active: showNotifications }">
      <img src="/assets/images/notification.svg" alt="notification" />
      @if (notificationsService.unred) {
      <span class="unred pos-abs">{{notificationsService.unred > 9 ? '+9' : notificationsService.unred}}</span>
      }
    </button>
    <button type="button" mat-mini-fab class="btn btn-a account" [ngClass]="{ active: this.globals.showSelfProfile }"
      (click)="toggleSelfProfile()" id="self-profile">
      @if (auth.user.avatar_url) {
      <img [src]="auth.user.avatar_url" alt="Avatar" class="avatar" />
      }{{ firstLetter }}
    </button>
    @if (showCreditsOptions) {
    <div class="credits-options text-start fade-in" (click)="this.toggleCreditsOptions(false)">
      <div class="ow">
        <a [routerLink]="['/topups']" class="btn btn-d disp-block w-100 text-start" mat-raised-button>add credits</a>
      </div>
      <div class="ow">
        <a [routerLink]="['/pricing']" class="btn btn-d disp-block w-100 text-start" mat-raised-button>change
          subscription plan</a>
      </div>
    </div>
    } @if (globals.showSelfProfile) {
    <div class="user-wrap pos-abs box-shadow fade-in text-start">
      <app-user-card class="disp-block" [user]="auth.user"></app-user-card>
    </div>
    } } @else {
    <a class="btn btn-a" mat-raised-button [routerLink]="['/login']" routerLinkActive="active"
      (click)="onAction('start_for_free')">start for free</a>
    }
  </div>
  @if (showNotifications) {
    <div class="pos-abs box-shadow fade-in text-start" id="notifications-summary">
      <app-notifications class="disp-block"></app-notifications>
    </div>
    }
  <div class="hidden-desktop pos-rel">
    <div class="pos-abs">
      @if (isloggedIn) {
      <a class="mobile-balance pos-abs pointer" [routerLink]="['/user-profile']" (click)="onAction('user-profile')">
        <span class="balance txt-sm bold">
          <img src="/assets/images/coin.svg" alt="coin" class="va-middle" width="20" height="21" />
          <span>{{ auth.credits | number }}</span>
        </span>
      </a>
      <button type="button" mat-raised-button class="btn btn-d no-marg-img btn-img notifications pos-rel"
        (click)="showNotifications = !showNotifications" [ngClass]="{ active: showNotifications }">
        <img src="/assets/images/notification.svg" alt="notification" />
        @if (notificationsService.unred) {
        <span class="unred pos-abs">{{notificationsService.unred > 9 ? '+9' : notificationsService.unred}}</span>
        }
      </button>
      }
      <button mat-raised-button aria-label="mobile menu" class="mobile-menu btn btn-c btn-img no-marg-img"
        (click)="showMenu = true">
        <img src="/assets/images/menu.svg" alt="menu" class="va-middle" />
      </button>
      <app-header-menu (on-close)="showMenu = false" [ngClass]="{ open: showMenu }"></app-header-menu>
    </div>
  </div>
</header>