import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  IReconstructJobUI,
  IReconstructionAction,
  RECONSTRUCT_JOB_STATUS,
} from '../generate';
import { GenerateService } from '../generate.service';
import { UtilsService } from '../../shared/utils.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import {
  IPlaygroundNotification,
  PlaygroundNotificationType,
} from '../../shared/enums';

@Component({
  selector: 'app-pre-creation-preview',
  standalone: true,
  imports: [],
  templateUrl: './pre-creation-preview.component.html',
  styleUrl: './pre-creation-preview.component.scss',
})
export class PreCreationPreviewComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() item: IReconstructJobUI;
  @Input() counter: number;
  public RECONSTRUCT_JOB_STATUS = RECONSTRUCT_JOB_STATUS;
  public InProgress = RECONSTRUCT_JOB_STATUS['In Progress'];
  private action: IReconstructionAction;
  private _subs: Array<Subscription>;
  private _destroyed: boolean;
  public percentage: number;
  constructor(
    private generateService: GenerateService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService
  ) {
    this._subs = [];
  }

  async ngOnInit() {
    this.action = await this.generateService.getAction(this.item.action_id);
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    this.calcPercentage();
  }

  ngOnChanges() {
    this.calcPercentage();
  }

  async onAnnouncement(data: IPlaygroundNotification) {
    if (
      data.notifications_types?.find(
        (t) =>
          t.id === PlaygroundNotificationType.JOB_STATUS_CHANGE ||
          t.id === PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED
      )
    )
      if (data.job_id === this.item?.id && this.item.status !== data.status) {
        this.item = await this.generateService.updateJob(this.item);
        this.action = await this.generateService.getAction(this.item.action_id);
        this.calcPercentage();
      }
  }

  async calcPercentage() {
    await this.utils.setTimeout();
    if (this._destroyed) return;
    if (
      this.action &&
      this.item.status === RECONSTRUCT_JOB_STATUS['In Progress']
    ) {
      setTimeout(this.calcPercentage.bind(this));
      // console.log('app-pre-creation-preview', this.action);
      this.percentage = this.utils.calcActionPercentage(this.item, this.action);
    }
  }

  ngOnDestroy() {
    this._destroyed = true;
    this._subs.forEach((s) => s.unsubscribe());
  }
}
