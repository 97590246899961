<div class="h-100 pos-rel wrap">
  <img [src]="item.url" class="disp-block creation vertical-align-desktiop-only" alt="reconstructed image">
  <div class="pos-abs create-wrap text-center horizontal-align">
    <button
      type="button"
      mat-raised-button
      class="btn btn-a w-100"
      (click)="generate()"
    >
      <img class="btn-icon" src="/assets/images/refine.svg" alt="generate model" />
      Generate model
    </button>
    <app-cost-est [cost]="price" [est]="60" class="disp-block"></app-cost-est>
  </div>
</div>