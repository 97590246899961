<div class="sso-wrap w-100">
  <div>
    <a
      type="button"
      mat-raised-button
      color="accent"
      class="btn btn-c big"
      [href]="getGoogleLoginLink()"
    >
      <img
        src="assets/images/google.svg"
        alt="sign in with google"
        class="va-middle"
      />
      Continue with <span class="capitalize">google</span>
    </a>
  </div>
  <div class="hidden">
    <a
      type="button"
      mat-raised-button
      color="accent"
      class="btn btn-c big"
    >
      <img
        src="assets/images/okta.svg"
        alt="sign in with okta"
        class="va-middle"
      />
      Continue with <span class="uppercase">okta</span>
    </a>
  </div>
</div>
