<div
  class="wrap"
  [ngClass]="{
    thumbs: type === CREATIONS_LIST_TYPE.THUMBS,
    'mobile-horizon-thumbs': type === CREATIONS_LIST_TYPE.HORIZON_THUMBS,
    'desktop-info-mobile-horizon-thumbs': type === CREATIONS_LIST_TYPE.DESKTOP_INFO_HORIZON_THUMBS_MOBILE
  }"
>
  @if (!creationsService.items) {
  <div
    class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center"
  >
    <img src="/assets/images/asset.svg" alt="asset" />
    <div class="desc txt-sm">Loading . . .</div>
  </div>
  } @else if (!creationsService.items.length) {
  <div
    class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center"
  >
    <img src="/assets/images/asset.svg" alt="asset" />
    <div class="desc txt-sm">No creations yet</div>
    <div class="hidden-mobile">
      <a routerLink="/generate" class="btn btn-a big" mat-raised-button>
        generate new model
      </a>
    </div>
  </div>
  } @else {
  <div class="list" #scroll id="creation-list">
    @for (item of creationsService.items; track $index) {
    <div class="card-wrap">
      <app-creation-card
        class="content-va"
        [type]="type"
        [item]="item"
        [counter]="creationsService.counter"
        [actions]="actions"
        (onSelect)="select($event)"
        (onRetry)="retry($event)"
        (onDuplicate)="duplicate($event)"
        (onDeleteMe)="deleteMe($event)"
      ></app-creation-card>
    </div>
    }
  </div>
  }
</div>
