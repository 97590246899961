import { Component } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { TermsViewComponent } from '../terms-view/terms-view.component';
import { MatButtonModule } from '@angular/material/button';
import { PrivacyViewComponent } from '../privacy-view/privacy-view.component';

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [MatDialogContent, TermsViewComponent, MatDialogActions, MatButtonModule, PrivacyViewComponent, MatDialogClose],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {

}
