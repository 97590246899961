<div class="popup text-center" *ngIf="popup">
    <div class="ttl-sm space-top"> {{popup.title}}</div>
    <div class="close pointer" (click)="close()"><span class="material-icons">
        close
      </span></div>
    <div class="sub-title ttl-xxs">{{popup.subtitle}}</div>
    <ol class="popup-list">
      <li *ngFor="let l of popup.list; let i = index">
        <span [innerHtml]="l"></span>
     
      <div *ngIf="data.num === 1 && i === 0"  fxLayout="row" fxLayoutAlign="space-between" class="qr-code-box">
        <div class="apple-box a-box">
          <div class="apple-qr qr"><img src="https://cdn.hexa3d.io/hotlink-ok/icons/apple_google_auth_app.png" alt="apple auth QR" /></div>
          <div class="apple-button button"><img src="https://cdn.hexa3d.io/hotlink-ok/icons/appstore.svg" class="center-me" alt="apple auth app" /></div>
        </div>
        <div class="android-box a-box">
          <div class="android-qr qr"><img src="https://cdn.hexa3d.io/hotlink-ok/icons/android_google_auth_app.png" alt="google auth QR" /></div>
          <div class="android-button button"><img src="https://cdn.hexa3d.io/hotlink-ok/icons/googleplay.svg" class="center-me" alt="google auth app" /></div>
        </div>
      </div>
    </li>
    </ol>
   
    <div class="sub-text">{{popup.text1}} </div>
    <div class="sub-text"> {{popup.text2}}</div>
    <button *ngIf="popup.button.length>0" (click)="onClick()" mat-button mat-dialog-close
      class="prime_button center-me min-button-width qr-submit">
      {{popup.button}}
    </button>
  </div>