import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { onError } from '@apollo/client/link/error';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
// import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { EndpointsService } from './communication/endpoints.service';
import { EndPoints } from './communication/endpoints';
import { StorageService } from 'ng-storage-service';
import { InMemoryCache, createHttpLink, ApolloLink, DefaultOptions } from '@apollo/client/core';
import { AuthInterceptor } from './communication/auth-interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SocialResolve } from './auth/social/social.resolve';
import { ActionsResolve } from './auth/actions.resolve';
import { TransactionsResolve } from './auth/user-profile/transactions.resolve';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.captchaSiteKey
    // },
    importProvidersFrom(
      ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled: environment.production
    })),
    // importProvidersFrom(ApolloModule),
    {
      provide: APOLLO_OPTIONS,
      useFactory:
        (httpLink: HttpLink) => {
          let endpoints = new EndpointsService();
          let endpoint = endpoints.getEndpointDomain(EndPoints.GRAPH);
          let storage = new StorageService();
  
          const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
              graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
              );
            if (networkError) {
              if (networkError.toString().indexOf('code 401') > -1) {
                storage.remove('user');
                storage.remove('token');
                window.location.pathname = 'login';
              }
            }
          })
  
          const auth = new ApolloLink((operation: any, forward: any) => {
            const token = storage.get('token');
            operation.setContext({
              headers: {
                Authorization: token ?? ''
              }
            });
            return forward(operation);
          });
  
          const hLink = createHttpLink({
            uri: endpoint
          });
  
          const defaultOptions = {
            watchQuery: {
              fetchPolicy: 'network-only',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'network-only',
              errorPolicy: 'all',
            },
          } as DefaultOptions;
  
          return {
            cache: new InMemoryCache(),
            link: ApolloLink.from([errorLink, auth, hLink]),
            defaultOptions: defaultOptions
          };
        },
      deps: [HttpLink],
    },
    Apollo,
    SocialResolve,
    ActionsResolve,
    TransactionsResolve
  ]
};
