import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { KeyValuePair } from '../enums';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { DropdownButtonComponent } from '../dropdown-button/dropdown-button.component';

@Component({
  selector: 'app-options-button',
  standalone: true,
  imports: [MatButtonModule, CommonModule, DropdownButtonComponent],
  templateUrl: './options-button.component.html',
  styleUrl: './options-button.component.scss',
})
export class OptionsButtonComponent implements OnChanges {
  @Input('css-class') cssClass: string;
  @Input() disabled: boolean;
  @Input() options: KeyValuePair[];
  @Input('current-key') currentKey: KeyValuePair[];
  @Output() onClick = new EventEmitter<KeyValuePair>();
  @Output() onChange = new EventEmitter<KeyValuePair>();
  public value: KeyValuePair;
  public isOpen: boolean;
  constructor() {
    this.isOpen = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentKey']) this.setCurrent();
  }

  private setCurrent() {
    if (this.currentKey && this.options)
      this.value = this.options.find((kvp) => kvp.key === this.currentKey);
  }

  public onValueChange(value: KeyValuePair): void {
    this.value = value;
    this.onChange.emit(this.value);
  }

  click() {
    this.onClick.emit(this.value);
  }

  openChange(state: boolean) {
    this.isOpen = state;
  }
}
