<div
  class="wrap"
  [ngClass]="{
    'horizontal-mobile': type === IMAGE_LIST_TYPE.HORIZONTAL_MOBILE
  }"
>
  @if (!giService.items) {
  <div
    class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center"
  >
    <img src="/assets/images/asset.svg" alt="asset" />
    <div class="desc txt-sm">Loading . . .</div>
  </div>
  } @else if (!giService.items.length) {
  <div
    class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center"
  >
    <img src="/assets/images/asset.svg" alt="asset" />
    <div class="desc txt-sm">No creations yet</div>
    <div class="hidden-mobile">
      <a routerLink="/generate" class="btn btn-a big" mat-raised-button>
        generate new image
      </a>
    </div>
  </div>
  } @else {
  <div class="list disp-flex row" #scroll id="creation-images-list">
    @for (item of giService.items; track $index) {
    <div class="item">
      <app-generated-image-card
        (click)="setImage(item)"
        class="content-va disp-block pointer"
        [item]="item"
      ></app-generated-image-card>
    </div>

    }
  </div>
  }
</div>
