@if (topups) {
<div class="disp-flex row mobile-col items">
  @for (item of topups; track $index) {
  <div class="flex-def">
    <app-topups-card
      class="disp-block h-100"
      [details]="item"
    ></app-topups-card>
  </div>
  }
</div>
}
