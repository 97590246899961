<div
  class="wrap pos-rel disp-flex row w-100 {{ cssClass }}"
  [ngClass]="{ 'dd-open': isOpen, disabled: disabled }"
>
  <button
    type="button"
    class="btn large"
    [disabled]="disabled"
    mat-raised-button
    (click)="click()"
  >
    {{ value.value }}
  </button>
  <app-dropdown-button
    [disabled]="disabled"
    (selectionChange)="onValueChange($event)"
    [options]="options"
    (openChange)="openChange($event)"
    css-class="transparent"
    [init-value]="value"
  ></app-dropdown-button>
</div>
