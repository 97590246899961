<div class="wrap view-max-width center-me">
  <h1 class="ttl-xl text-center">Choose your plan</h1>
  <div class="text-center inner">
    <div class="buttons disp-inline-flex">
      <div class="button-wrap">
        <button
          (click)="state = MONTHLY"
          [ngClass]="{ active: state === MONTHLY }"
          type="button"
          mat-raised-button
          class="btn btn-g"
        >
          monthly
        </button>
      </div>
      <div class="button-wrap">
        <button
          (click)="state = YEARLY"
          [ngClass]="{ active: state === YEARLY }"
          type="button"
          mat-raised-button
          class="btn btn-g"
        >
          yearly (20% off)
        </button>
      </div>
    </div>
    <app-pricing-view [pricing-period]="state"></app-pricing-view>
    <div class="enterprise text-start disp-flex row mobile-col">
      <div>
        <h2 class="ttl-xs">Enterprise</h2>
        <div class="e-desc">
          Ready for an enterprise-level 3D experience? Get in touch to learn how
          we can elevate your projects to new dimensions
        </div>
      </div>
      <div class="flex-1">
        <div class="disp-flex">
          <a
            href="https://www.hexa3d.io/#contact-area"
            target="_blank"
            mat-raised-button
            class="btn btn-c big align-end-flex cu"
          >
            contact us
          </a>
        </div>
      </div>
    </div>
    <div class="text-start note">
      <p>Please note:</p>
      <p>
        Your subscription will automatically renew at the end of each billing
        cycle. Once charged, we do not offer refunds.
      </p>
      <p>
        You can cancel your monthly subscription at any time from the profile
        page.
      </p>
    </div>
  </div>
</div>
