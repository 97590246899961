@if (profile) {
<div class="wrap component-wrap">
  <div class="disp-flex row mobile-col top-funnel">
    <div class="flex-def bg">
      <div
        class="ttl-xxs bold capitalize-fl space-bottom-lg border-bottom padd"
      >
        personal details
      </div>
      <div class="personal-details disp-flex row align-items-center padd-sides">
        <span class="capitalize-fl"> avatar </span>
        @if (profile.user.avatar_url) {
        <img [src]="profile.user.avatar_url" alt="Avatar" class="avatar" />
        } @else {
        <div class="first-letter">
          {{ firstLetter }}
        </div>
        }
        <span
          type="button"
          class="btn btn-d pointer pos-rel"
          mat-raised-button
          (click)="selectFile()"
        >
          <mat-progress-bar
            class="pos-abs"
            [ngClass]="{ 'disp-none': !uploading }"
            mode="indeterminate"
          ></mat-progress-bar>
          <img src="/assets/images/pencil.svg" alt="pencil" class="va-middle" />
          Edit
        </span>
      </div>
      <div class="disp-flex row mobile-col name-email padd padd-bottom">
        <div class="disp-flex col w-50">
          <div class="disp-flex space-bottom-sm">
            <span class="txt-xxs bold capitalize-fl">name</span>
          </div>
          <mat-form-field
            class="w-100 hide-placeholder ta-wrap flex-1 no-subscript"
          >
            <input
              type="text"
              [(ngModel)]="profile.user.firstname"
              placeholder="first name"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="disp-flex col w-50">
          <div class="disp-flex space-bottom-sm">
            <span class="txt-xxs bold capitalize-fl">email</span>
          </div>
          <mat-form-field
            class="w-100 hide-placeholder ta-wrap flex-1 no-subscript"
          >
            <input
              disabled="true"
              type="text"
              [(ngModel)]="profile.user.email"
              placeholder="email"
              matInput
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="flex-def bg">
      <h4 class="capitalize-fl ttl-xxs bold space-bottom-lg border-bottom padd">
        subscription
      </h4>
      <div class="disp-flex padd-sides">
        <div class="flex-def">
          <span class="sub-label capitalize-fl">
            @if (profile.subDetails) {
            {{ profile.subDetails.name }}
            } @else { free }
          </span>
        </div>
        <div>
          <a
            [routerLink]="['/pricing']"
            mat-raised-button
            class="btn btn-a align-end-flex"
            >upgrage plan</a
          >
        </div>
      </div>
      <div class="disp-flex padd-sides">
        <span
          class="money ttl-md bold no-left-pad"
          [ngClass]="{ 'has-sub': profile.subDetails?.billingCycle }"
        >
          @if (profile.subDetails) {
          {{ profile.subDetails.price }}$ } @else {0$}
        </span>
        <span class="interval txt-xxs">
          @if (profile.subDetails?.billingCycle) {
          <div class="lh">Per</div>
          <div>
            {{ profile.subDetails.billingCycle.interval }}
          </div>
          }
        </span>
      </div>
      <div class="padd padd-bottom">
        @if (profile.subDetails?.billingCycle) {
        <div class="disp-flex row txt-sm">
          <div class="flex-def capitalize-fl">
            {{ profile.subDetails.billingCycle.interval }}ly credits
          </div>
          <div class="bold">
            {{ profile.credits | number }}/{{
              profile.subDetails.credits | number
            }}
          </div>
        </div>
        <div class="percentage">
          <app-progress-bar
            [percentage]="100 * (profile.credits / profile.subDetails.credits)"
          ></app-progress-bar>
        </div>
        } @else {
        <div class="disp-flex row txt-sm">
          <div class="flex-def capitalize-fl">Monthly credits</div>
          <div class="bold">
            {{ profile.credits | number }}/{{ defaultMonthlyCredits }}
          </div>
        </div>
        <div class="percentage">
          <app-progress-bar
            [percentage]="100 * (profile.credits / defaultMonthlyCredits)"
          ></app-progress-bar>
        </div>
        }
      </div>
      <!-- <div class="current-plan capitalize-fl space-bottom-md">
        <div class="disp-flex row align-items-center">
          <span class="flex-def capitalize-fl">
            @if (profile.subDetails) {
            {{ profile.subDetails.name }}
            } @else { free }
          </span>
          <span class="mw-50">|</span>
          <span class="flex-def"> {{ profile.credits }} credits left </span>
          <span class="flex-def disp-flex">
            <button
              type="button"
              class="btn btn-a align-end-flex"
              mat-raised-button
              (click)="upgrage()"
            >
              upgrage
            </button>
          </span>
        </div>
      </div> -->
    </div>
  </div>

  <div class="disp-flex usage-wrap space-bottom-lg">
    <div class="flex-def">
      <h4 class="capitalize-fl ttl-xxs bold space-bottom-lg">app usage</h4>
      <app-usage [transactions]="transactions"></app-usage>
    </div>
  </div>
  <div class="disp-flex row bottom-buttons">
    <div class="flex-def">
      <button
        type="button"
        class="btn btn-d align-end-flex delete large sp"
        mat-raised-button
        (click)="deleteAccount()"
      >
        <img
          src="/assets/images/trash.svg"
          alt="trash"
          class="va-middle invert icon-sm"
        />
        Delete account
      </button>
    </div>
    <div class="flex-def disp-flex">
      <div class="align-end-flex">
        <button
          type="button"
          class="btn btn-b save large"
          mat-raised-button
          (click)="save()"
        >
          save changes
        </button>
      </div>
    </div>
  </div>
</div>
}
