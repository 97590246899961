import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { KeyValuePair } from '../enums';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-dropdown-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatSelectModule, MatButtonModule],
  templateUrl: './dropdown-button.component.html',
  styleUrl: './dropdown-button.component.scss',
})
export class DropdownButtonComponent {
  @Input('css-class') cssClass: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() options: KeyValuePair[];
  @Input('init-value') initValue: any;
  @Output() selectionChange = new EventEmitter<KeyValuePair>();
  @Output() openChange = new EventEmitter<boolean>();
  @ViewChild('dropdown') dropdown: MatSelect;
  public isOpen = false;

  public onButtonClick(): void {
    if (this.options.length) {
      this.dropdown.toggle();
    } else {
      this.selectionChange.emit({ key: undefined, value: undefined });
    }
  }

  openedChange(state: boolean) {
    this.isOpen = state;
    this.openChange.emit(state);
  }
}
