import { PlatformName } from "../constants";
import { KeyValuePair } from "../enums";

export class MetaOptions {
    public description: string;
    public keywords: string;
    public canonical: string;
    public title: string;
    public og_image: string;
    public og_title: string;
    public og_type: string;
    public og_url: string;
    public properties: Array<KeyValuePair>
    constructor() {
        this.description = `${PlatformName} is an automated 3D content creation platform that allow users to create 3D models out of text prompts or images.`;
        this.keywords = 'HexaGen, Free, 3D Models, AI';
        this.canonical = window.location.href;
        this.title = PlatformName;
        // this.og_image = 'https://cdn.creators3d.com/site/poster_v1.jpg';
        this.og_title = `${PlatformName} - Create 3D models with AI`;
        this.og_type = 'website';
        // this.og_url = this.canonical;
        this.properties = [];
    }
}