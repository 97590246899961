import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { IAuthType } from '../../shared/enums';

@Injectable()
export class RecoverPasswordResolve {
  constructor(private auth: AuthService) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.auth.authType.next(IAuthType.CONFIRMED);
    return route.params['token'];
  }
}
