import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { HexaLogoComponent } from '../hexa-logo/hexa-logo.component';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../utils.service';
import { UserCardComponent } from '../../auth/user-card/user-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { GlobalsService } from '../globals.service';
import { CommonModule } from '@angular/common';
import { PaddleService } from '../../payments/paddle.service';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    HexaLogoComponent,
    MatMenuModule,
    UserCardComponent,
    CommonModule,
    HeaderMenuComponent,
    NotificationsComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnDestroy {
  public isloggedIn: boolean;
  public firstLetter: string;
  public showCreditsOptions: boolean;
  public showMenu: boolean;
  private _subs: Array<Subscription>;
  // private _showNotifications: boolean;
  constructor(
    public auth: AuthService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    public globals: GlobalsService,
    private paddleService: PaddleService,
    public notificationsService: NotificationsService
  ) {
    this._subs = [];
    this.globals.showSelfProfile = false;
    this.showCreditsOptions = false;
    // this._showNotifications = false;
    this.init();
    this._subs.push(
      this.broadcaster.on('onLogin').subscribe(this.init.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onLogout').subscribe(this.init.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onclick').subscribe((this as any).onclick.bind(this))
    );
  }
  
  public get showNotifications(): boolean {
    return this.notificationsService.getSidebarState();
  }

  public set showNotifications(value: boolean) {
    setTimeout(() => {
      this.notificationsService.toggleSidebar(value);
      // this._showNotifications = value;
      if (value) {
        this.toggleSelfProfile(false);
        this.toggleCreditsOptions(false);
      }
    });
  }

  onclick(elm: Element) {
    if (this.showNotifications) {
      if (!this.utils.closest(elm, '#notifications-summary') && !this.utils.closest(elm, '.cdk-overlay-container')) {
        this.showNotifications = false;
      }
    }
    else {
      if (this.globals.showSelfProfile || this.showCreditsOptions) {
        if (!this.utils.closest(elm, '#users-summary')) {
          this.toggleSelfProfile(false);
          this.toggleCreditsOptions(false);
        }
      }
      else {
        this.toggleSelfProfile(false);
        this.toggleCreditsOptions(false);
      }
    }
  }

  async init() {
    this.isloggedIn = await this.auth.isloggedIn();
    if (this.isloggedIn) {
      this.firstLetter = this.auth.user.email.substring(0, 1);
    }
  }

  toggleSelfProfile(state?: boolean) {
    if (state && this.showCreditsOptions) this.toggleCreditsOptions(false);
    if (typeof state === 'boolean') this.globals.showSelfProfile = state;
    else this.globals.showSelfProfile = !this.globals.showSelfProfile;
    if (this.globals.showSelfProfile) this.showCreditsOptions = false;
  }

  toggleCreditsOptions(state: boolean) {
    if (state)
      this.paddleService.prefetch();
    if (state && this.globals.showSelfProfile) this.toggleSelfProfile(false);
    this.showCreditsOptions = state;
  }

  logout() {
    this.auth.logout();
  }

  onAction(route: string) {
    this.broadcaster.broadcast('onHeaderAction', route);
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }
}
