<div
  class="wrap"
  [ngClass]="{ 'after-in': init, imm: loaded }"
  [ngStyle]="{ 'transition-delay': (item._delayEnter || 0) + 'ms' }"
>
  <img
    [src]="item.url | srcSuffix : '?w=84&h=84'"
    alt="reconstructed image"
    loading="lazy"
  />
</div>
