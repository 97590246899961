<div class="dropdown-button-container {{ cssClass }}">
  <button
    type="button"
    [disabled]="disabled"
    class="w-100"
    mat-raised-button
    (click)="onButtonClick()"
  >
    {{ label }}
    <ng-container *ngIf="options?.length">
      <mat-icon [ngClass]="{ 'flip-me': dropdown.focused && isOpen }"
        >expand_more</mat-icon
      >
    </ng-container>
  </button>
  <mat-form-field>
    <mat-select
      #dropdown
      (openedChange)="openedChange($event)"
      (selectionChange)="selectionChange.emit($event.value)"
      [value]="initValue"
    >
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option.value | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
