import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  GENERATE_TYPE,
  IReconstruct,
  RECONSTRUCTION_ACTION,
} from '../generate';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { UtilsService } from '../../shared/utils.service';
import {
  ScreenNotificationType,
  KeyValuePair,
  RECONSTRUCTION_OPTIONS,
} from '../../shared/enums';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { GenerateService } from '../generate.service';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { MatIconModule } from '@angular/material/icon';
import { OptionsButtonComponent } from '../../shared/options-button/options-button.component';
import { EnumsService } from '../../shared/enums.service';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';

@Component({
  selector: 'app-prompt',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    OptionsButtonComponent,
    CostEstComponent,
  ],
  templateUrl: './prompt.component.html',
  styleUrl: './prompt.component.scss',
})
export class PromptComponent implements OnDestroy, OnInit {
  private _state: GENERATE_TYPE;
  private _examplePromptIndex: number;
  public TEXT_TO_3D = GENERATE_TYPE.TEXT_TO_3D;
  public IMAGE_TO_3D = GENERATE_TYPE.IMAGE_TO_3D;
  public TEXT_TO_IMAGE_TO_3D = RECONSTRUCTION_OPTIONS.TEXT_TO_IMAGE_TO_3D;
  public imageToUpload: string;
  public uploadingImage: boolean;
  public allTextOptions: Array<KeyValuePair>;
  public current2dType: RECONSTRUCTION_OPTIONS;
  public reconstructionCost: number;
  constructor(
    private utils: UtilsService,
    private uploadService: ResumableUploadService,
    public generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService
  ) {
    this.state = GENERATE_TYPE.TEXT_TO_3D;
    this._examplePromptIndex = 0;
    this.allTextOptions = this.enums.getTextOptions();
    this.current2dType = this.allTextOptions[0].key;
    this.init();
  }

  get state() {
    return this._state;
  }

  set state(value: GENERATE_TYPE) {
    this._state = value;
  }

  private async init() {
    this.reconstructionCost = (
      await this.generateService.getAction(RECONSTRUCTION_ACTION.RECONSTRUCTION)
    ).credits;
  }

  ngOnInit() {
    const params = this.utils.getAngularUrlParams();
    if (params?.duplicate)
      this.duplicate(parseInt(params.duplicate));
  }

  async duplicate(id: number) {
    if (id && !isNaN(id)) {
      const job = (await this.generateService.getJobById(id)).data.reconstruction_jobs;
      switch (job.source_action_id || job.action_id) {
        case RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT: {
          this.state = this.TEXT_TO_3D;
          this.generateService.text = job.text;
          break;
        }
        case RECONSTRUCTION_ACTION.RECONSTRUCTION: {
          this.state = this.IMAGE_TO_3D;
          this.generateService.text = job.reconstruction_jobs_inputs[0].text_input;
          this.imageToUpload = job.preview;
          break;
        }
      }
    }
  }

  async generateFromText() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_text',
    });
    const payload = {
      action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
      text: this.generateService.text,
    } as IReconstruct;
    this.getSimilarFromText();
    const job = await this.generateService.imageTo3D(payload);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'generating . . .',
    });
    this.generateService.text = null;
    this.broadcaster.broadcast('onGenerating', job);
  }

  async generate() {
    switch (this.state) {
      case GENERATE_TYPE.TEXT_TO_3D: {
        await this.generateService.generateImagesFromText();
        // payload.method = 'TRIPOSR';
        // payload.text = this.text;
        break;
      }
      case GENERATE_TYPE.IMAGE_TO_3D: {
        const payload = {
          action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
          text: this.generateService.text,
          images: [this.imageToUpload],
        } as IReconstruct;
        if (this.imageToUpload) this.getSimilarFromImage(this.imageToUpload);
        else this.getSimilarFromText();
        const job = await this.generateService.imageTo3D(payload);
        // await this.utils.observableToPromise(
        //   this.rest.reconstruct('POST', payload)
        // );
        this.utils.notifyUser({
          type: ScreenNotificationType.Neutral,
          text: 'generating . . .',
        });
        this.generateService.text = null;
        this.broadcaster.broadcast('onGenerating', job);
      }
    }
  }

  async getSimilarFromText() {
    const res = await this.generateService.reconstructImage(
      this.generateService.text
    );
    await this.getSimilarFromImage(res[0].url);
  }

  async getSimilarFromImage(url: string) {
    this.generateService.similarItems =
      await this.generateService.getSimilarProducts(url);
  }

  async onFilesChange(event: any) {
    const file = event.srcElement.files[0];
    if (!file) return;
    this.uploadingImage = true;
    try {
      this.imageToUpload = await this.utils.getBase64FromFile(file);
    } catch (e) {}
    this.imageToUpload = await this.uploadService.file(file);
    event.srcElement.value = '';
    if (!this.generateService.text)
      this.generateService.text =
        await this.generateService.getImageClassification(
          this.imageToUpload,
          true
        );
    this.uploadingImage = false;
  }

  isDisable() {
    if (this.state === this.IMAGE_TO_3D) {
      return (
        !this.imageToUpload ||
        !!this.uploadingImage ||
        !this.generateService.text
      );
    } else {
      return !this.generateService.text;
    }
  }

  generateImages() {
    this.onTextGen(this.allTextOptions[0]);
  }

  onTextGen(selection: KeyValuePair) {
    switch (selection.key) {
      case RECONSTRUCTION_OPTIONS.TEXT_TO_3D: {
        this.generateFromText();
        break;
      }
      case RECONSTRUCTION_OPTIONS.TEXT_TO_IMAGE_TO_3D: {
        this.generate();
        break;
      }
    }
  }

  onTypeChange(type: KeyValuePair) {
    this.current2dType = type.key;
  }

  examplePrompt() {
    const prompts = this.enums.getExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.generateService.text = p;
  }

  ngOnDestroy() {
    delete this.generateService.creation;
    delete this.generateService.createdImages;
  }
}
