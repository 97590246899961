<div class="component-wrap">
  <div class="section">
    <table>
      <tr>
        <td>
          <button mat-raised-button class="btn btn-a">primary</button>
        </td>
        <td>
          <button mat-raised-button class="btn btn-a big">primary big</button>
        </td>
        <td>
          <button mat-raised-button class="btn btn-b big">secondary big</button>
        </td>
        <td>
          <button mat-raised-button class="btn btn-c big">ghost big</button>
        </td>
      </tr>
      <tr>
        <td>
          <button disabled="true" mat-raised-button class="btn btn-a">
            primary disabled
          </button>
        </td>
        <td>
          <button disabled="true" mat-raised-button class="btn btn-a big">
            primary big disabled
          </button>
        </td>
        <td>
          <button disabled="true" mat-raised-button class="btn btn-b big">
            secondary big disabled
          </button>
        </td>
        <td>
          <button disabled="true" mat-raised-button class="btn btn-c big">
            ghost big disabled
          </button>
        </td>
      </tr>
    </table>
  </div>

  <div class="section">
    <div class="ttl-hero">heading/hero</div>
    <br />
    <div class="ttl-xl">heading/xl</div>
    <br />
    <div class="ttl-lg">heading/lg</div>
    <br />
    <div class="ttl-md">heading/md</div>
    <br />
    <div class="ttl-sm">heading/sm</div>
    <br />
    <div class="ttl-xs">heading/xs</div>
    <br />
    <div class="ttl-xxs">heading/xxs</div>
    <br />
    <div class="ttl-xxs">heading/xxs</div>
    <br />
  </div>

  <div class="section">
    <div class="txt-lg">body/lg</div>
    <br />
    <div class="txt-md">body/md</div>
    <br />
    <div class="txt-md bold">body/md-semibold</div>
    <br />
    <div class="txt-sm">body/sm</div>
    <br />
    <div class="txt-sm underline">body/sm-link</div>
    <br />
    <div class="txt-sm bold">body/sm-semibold</div>
    <br />
    <div class="txt-xs">body/xs</div>
    <br />
    <div class="txt-xs bold">body/xs-smibold</div>
    <br />
    <div class="txt-xxs">body/xxs</div>
    <br />
    <div class="txt-xxs bold">body/xxs-semibold</div>
    <br />
    <div class="txt-xxs bold underline">body/xxs-semibold-link</div>
    <br />
  </div>

  <div class="section">
    <div class="err-color">This is the message of error status</div>
    <br>
    <div class="warn-color">This is the message of warning status</div>
    <br>
    <div class="success-color">This is the message of success status</div>
    <br>
  </div>

</div>
