import { Component } from '@angular/core';
import { TopupsViewComponent } from '../topups-view/topups-view.component';
import { SeoService } from '../../shared/seo/seo.service';
import { MetaOptions } from '../../shared/seo/seo';
import { PlatformName } from '../../shared/constants';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-topups',
  standalone: true,
  imports: [TopupsViewComponent],
  templateUrl: './topups.component.html',
  styleUrl: './topups.component.scss',
})
export class TopupsComponent {
  constructor(private seo: SeoService) {
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | Topups Pricing`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI 3D models topups pricing`;
    mOptions.description = `${mOptions.description} On this page you can explore the platform's topups pricing.`;
    mOptions.canonical = `${environment.domain}topups`;
    this.seo.setMetaDate(mOptions);
  }
}
