@if (visible && isBeforeinstallEnabled) {
  <div class="wrap text-center">
    Would you like to add {{PlatformName}} to your homescreen?
    <button mat-raised-button type="button" class="btn btn-a small" (click)="add()">
      Yes
    </button>
    <button mat-raised-button type="button" class="btn btn-c small" (click)="hide()">
      Later
    </button>
  </div>  
}