import { Component, Input } from '@angular/core';
import { IUserProfile, User } from '../user';
import { AuthService } from '../auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { PaddleService } from '../../payments/paddle.service';
// import { MatDialog } from '@angular/material/dialog';
// import { UserProfileComponent } from '../user-profile/user-profile.component';
import { ProgressBarComponent } from '../../shared/progress-bar/progress-bar.component';
import { Router } from '@angular/router';
import { GlobalsService } from '../../shared/globals.service';
import { DecimalPipe } from '@angular/common';
import { defaultMonthlyCredits } from '../../shared/constants';

@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    ProgressBarComponent,
    DecimalPipe
  ],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss',
})
export class UserCardComponent {
  @Input() user: User;
  public showRetailersSwitch: boolean;
  public profile: IUserProfile;
  public firstLetter: string;
  public defaultMonthlyCredits = defaultMonthlyCredits;
  constructor(
    public auth: AuthService,
    private paddleService: PaddleService,
    private globals: GlobalsService,
    // private dialog: MatDialog,
    private router: Router
  ) {}

  async ngOnInit() {
    this.profile = await this.paddleService.getUserProfile();
    this.firstLetter = this.auth.user.email.substring(0, 1);
    // {
    //   user: this.auth.user,
    //   credits: this.auth.credits,
    //   subscription: this.auth.subscription,
    //   subDetails: this.auth.subscription
    //     ? await this.paddleService.getPriceDetails(
    //         this.auth.subscription.product_id,
    //         this.auth.subscription.price_id
    //       )
    //     : null,
    // } as IUserProfile;
  }

  logout() {
    this.auth.logout();
  }

  async showFull() {
    this.router.navigate(['/user-profile']);
    this.globals.showSelfProfile = false;
    // this.dialog.open(UserProfileComponent, {
    //   width: '83.3vw',
    //   data: this.profile,
    // });
  }
}
