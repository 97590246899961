import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class ActionsResolve {
  constructor(private auth: AuthService) {}

  resolve() {
    return this.auth.getReconstructionActions();
  }
}
