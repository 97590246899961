import { Component } from '@angular/core';
import { EnumsService } from '../../shared/enums.service';
import { KeyValuePair } from '../../shared/enums';
import { ReactiveFormsModule } from '@angular/forms';
import { CreationsService } from '../creations.service';
import { MatButtonModule } from '@angular/material/button';
import { RECONSTRUCTION_ACTION } from '../generate';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-creations-filter',
  standalone: true,
  imports: [ReactiveFormsModule, MatButtonModule, CommonModule],
  templateUrl: './creations-filter.component.html',
  styleUrl: './creations-filter.component.scss',
})
export class CreationsFilterComponent {
  public filters: Array<KeyValuePair>;
  public dictionary: { [id: number]: boolean };
  constructor(
    public creationsService: CreationsService,
    private enums: EnumsService
  ) {
    this.filters = this.enums.getJobsFilterOptions();
    this.dictionary = {};
    this.filters.forEach(f => {
      this.dictionary[f.key] = false;
    });
  }

  toggle(type: RECONSTRUCTION_ACTION) {
    this.dictionary[type] = !this.dictionary[type];
    const arr = this.creationsService.filter.controls['action_id'].value as Array<number>;
    const i = arr.indexOf(type);
    if (this.dictionary[type]) {
      if (i === -1)
        arr.push(type);
    }
    else {
      if (i > -1)
        arr.splice(i, 1);
    }
    this.creationsService.filter.controls['action_id'].setValue(arr);
  }

  toggleArchived() {
    this.creationsService.filter.controls['is_archived'].setValue(!this.creationsService.filter.controls['is_archived'].value);
  }
}
