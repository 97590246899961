import { Component } from '@angular/core';
import { StorageService } from 'ng-storage-service';
import { MatButtonModule  } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { KeyValueAnyPair } from '../enums';
// import { EnumsService } from '../enums.service';

@Component({
  selector: 'app-cookies-compliance',
  standalone: true,
  imports: [MatButtonModule, RouterLink],
  templateUrl: './cookies-compliance.component.html',
  styleUrl: './cookies-compliance.component.scss'
})
export class CookiesComplianceComponent {
  static CCA = 'cookies-compliance-approved';
  public visible: boolean;
  public consentOptions: Array<KeyValueAnyPair>;
  constructor(
    private storage: StorageService,
    // private enums: EnumsService
  ) {
    // this.consentOptions = this.enums.getCookiesOptions();
    const c = this.storage.get(CookiesComplianceComponent.CCA);
    this.visible = !!!c;
    // this.visible = true;
  }

  approve() {
    // this.storage.set(CookiesComplianceComponent.CCA, this.consentOptions);
    this.storage.set(CookiesComplianceComponent.CCA, new Date().getTime());
    this.visible = false;
    // gtag('consent', 'default', {
    //   'ad_storage': 'denied',
    //   'ad_user_data': 'denied',
    //   'ad_personalization': 'denied',
    //   'analytics_storage': 'denied'
    // });
  }
}
