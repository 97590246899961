@if (globals.numOfRequestsInProgress) {
<mat-progress-bar class="main-progress-bar" mode="indeterminate">
</mat-progress-bar>
}
<div>
  <app-header [ngClass]="{'blur': body.isBlur}" class="pos-rel"></app-header>
  <div class="router-outlet-wrap" [ngClass]="{'blur': body.isBlur}">
    <router-outlet></router-outlet>
  </div>
  <app-cookies-compliance></app-cookies-compliance>
  <app-homescreen-teaser></app-homescreen-teaser>
</div>
